import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import CreateWorkflowButton from './CreateWorkflowButton';
import useApiHooks from '../../utils/useApiHooks';
import useShowErrorAlert from '../../utils/customHooks';

function UpdateModal({
  updateWorkflow, updateModalHandler, updateWorkflowId, currentAppId, handleWorkflowUpdated,
  updateWorkflowRowData,
}) {
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpdate, setEnableUpdate] = useState(false);
  const showErrorAlert = useShowErrorAlert();
  const { publishWorkflow } = useApiHooks();

  const handleTextChange = (value, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setEnableUpdate(Boolean(
      workflowData?.name?.length
        && workflowData?.description?.length,
    ));
  }, [workflowData]);
  const handleModal = () => {
    setWorkflowData({});
    updateModalHandler(false);
  };

  const handleUpdateButton = () => {
    updateWorkflow(
      workflowData,
      updateWorkflowRowData,
      handleModal,
      updateWorkflowId,
      currentAppId,
      publishWorkflow,
      handleWorkflowUpdated,
      showErrorAlert,
      setWorkflowData,
    );
  };
  return (
    <div>
      <div className="import_workflow__drawer_background">
        <div className="import_workflow__drawer">
          <TextInput
            label="Name of Workflow"
            placeholder="Edit name of your workflow"
            onChange={(value) => handleTextChange(value, 'name')}
          />
          <TextInput
            label="Description"
            placeholder="Edit description of your workflow"
            onChange={(value) => handleTextChange(value, 'description')}
          />
          <div className="import_workflow__item">
            <button type="button" className="import_workflow__button" onClick={handleModal}>Cancel</button>
            { enableUpdate
              ? (
                <CreateWorkflowButton
                  onButtonClick={handleUpdateButton}
                  normalButtonText="Update"
                  loadingButtonText="Updating"
                  buttonClassName="import_workflow__button__primary"
                  isLoading={false}
                />
              )
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateModal;

UpdateModal.propTypes = {
  updateWorkflow: PropTypes.func.isRequired,
  updateModalHandler: PropTypes.func.isRequired,
  updateWorkflowId: PropTypes.string.isRequired,
  handleWorkflowUpdated: PropTypes.func.isRequired,
  updateWorkflowRowData: PropTypes.object.isRequired,
  currentAppId: PropTypes.string.isRequired,
};
