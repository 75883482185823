import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import NestedDropdownWithInputs from '../ViewWorkflow/v2/NestedDropdown/NestedDropdown';
import './ModuleInputsEditPanel.scss';

// TODO Add visible property
function ModuleOutputsEditPanel({
  id,
  name,
  description,
  dropDownOptions,
  selectedValue,
  setModuleOutputProperty,
}) {
  return (
    <div className="module-builder-inputs-container">
      <div className="module-builder-inputs-container-dropdown">
        <NestedDropdownWithInputs
          defaultValue={selectedValue}
          items={dropDownOptions}
          noSelectLabel="Select Module Property type"
          onChange={(selectedItem) => setModuleOutputProperty(id, 'selectedValue', selectedItem.value)}
        />
      </div>
      <TextField
        label="Input name"
        variant="outlined"
        value={name}
        onChange={(event) => setModuleOutputProperty(id, 'name', event.target.value)}
        size="large"
        className="module-builder-inputs-container-textfield"
      />
      <TextField
        label="Input description"
        variant="outlined"
        value={description}
        onChange={(event) => setModuleOutputProperty(id, 'description', event.target.value)}
        size="large"
        className="module-builder-inputs-container-textfield"
      />
    </div>
  );
}

ModuleOutputsEditPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  dropDownOptions: PropTypes.string.isRequired,
  selectedValue: PropTypes.string,
  setModuleOutputProperty: PropTypes.func.isRequired,
};

ModuleOutputsEditPanel.defaultProps = {
  description: '',
  selectedValue: null,
};

export default ModuleOutputsEditPanel;
