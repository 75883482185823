import PropTypes from 'prop-types';
import { useState } from 'react';
import { TextField } from '@mui/material';
import NestedDropdownWithInputs from '../ViewWorkflow/v2/NestedDropdown/NestedDropdown';
import ValidationDrawer from '../FormModule/Common/ValidationDrawer';
import './ModuleInputsEditPanel.scss';

const getHelpText = (inputType) => {
  switch (inputType) {
    case 'toggle':
      return `
      1. Possible Values are by default expected to be boolean. Sample Input [true, false]
      2. Provide in double quotes to set them as string. Example ["yes", "no"]
      3. First element of array is set as on, second as no.
      `;
    case 'singleSelectDropDown':
      return `
        1. Possible Values is expected to be passed as an array.
        2. An additional Property Possible Value Labels would be available which also expects an array containing labels of possible values stored as text in config.
        3. An additional Property title would also be available which is set as title in config.
        4. Input Name would be used as label.
        5. Provide in double quotes to set them as string. Example ["yes", "no"]
        `;
    case 'multiSelectDropDown':
      return `
            1. Possible Values is expected to be passed as an array of objects like 
            [
              {"value": 200, "label": "200"},
              {"value": 201, "label": "201"},...
            ]
            2. An additional Property title would also be available which is set as title in config.
            3. Input Name would be used as label in config.
            4. Provide in double quotes to set them as string. Example ["yes", "no"]
            `;
    case 'inputToModule':
    case 'textbox':
      return 'Go wild!';
    default:
      return `Please select a valid input type to get info. current selected ${inputType}`;
  }
};

function ModuleInputsEditPanel({
  id,
  name,
  inputType,
  description,
  setModuleInputProperty,
  category,
  inputModuleKey,
  possibleValues,
  regexForTextBox,
  errorMessageForTextBox,
  placeholderForTextBox,
  required,
  defaultValue,
  isBuilderPropertiesSection = false,
  visible,
  moduleBuilderProperties,
  moduleIdsDropdown,
  inputModuleId,
  possibleValuesLabels,
  inputTitle,
}) {
  const parsedDropDownOptions = moduleBuilderProperties.map((option) => ({
    label: `builderProperties.${option.name}`,
    value: `builderProperties.${option.name}`,
  }));
  const [selectedInputType, setSelectedInputType] = useState(inputType);
  return (
    <div className="module-builder-inputs-container">
      <div className="conditional__content__rules_info">
        <div className="conditional__content__rules_info_heading"> Rules to follow</div>
        <pre>
          {getHelpText(selectedInputType)}
        </pre>
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <NestedDropdownWithInputs
          defaultValue={category}
          items={{
            items: [{
              label: 'Input',
              value: 'Input',
            },
            {
              label: 'Configuration',
              value: 'Configuration',
            }],
          }}
          noSelectLabel="Select Category type"
          onChange={(selectedItem) => setModuleInputProperty(id, 'category', selectedItem.value)}
        />
      </div>
      <div className="module-builder-inputs-container-dropdown">
        <NestedDropdownWithInputs
          defaultValue={inputType}
          items={{
            items: [{
              label: 'Toggle',
              value: 'toggle',
            },
            {
              label: 'Single Select',
              value: 'singleSelectDropDown',
            },
            {
              label: 'Multi Select',
              value: 'multiSelectDropDown',
            },
            {
              label: 'Input to Module',
              value: 'inputToModule',
            },
            {
              label: 'TextBox',
              value: 'textbox',
            },
            ],
          }}
          noSelectLabel="Select Input type"
          onChange={(selectedItem) => {
            setModuleInputProperty(id, 'inputType', selectedItem.value);
            setSelectedInputType(selectedItem.value);
          }}
        />
      </div>
      <TextField
        label="Input name"
        variant="outlined"
        value={name}
        onChange={(event) => setModuleInputProperty(id, 'name', event.target.value)}
        size="large"
        className="module-builder-inputs-container-textfield"
      />
      <TextField
        label="Input description"
        variant="outlined"
        value={description}
        onChange={(event) => setModuleInputProperty(id, 'description', event.target.value)}
        size="large"
        className="module-builder-inputs-container-textfield"
      />
      {!isBuilderPropertiesSection ?
        (
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <NestedDropdownWithInputs
              onChange={(selectedItem) => setModuleInputProperty(id, 'inputModuleId', selectedItem.value)}
              noSelectLabel="Select Module Id"
              items={moduleIdsDropdown}
              defaultValue={inputModuleId}
            />
            <TextField
              label="Input key"
              variant="outlined"
              value={inputModuleKey}
              onChange={(event) => setModuleInputProperty(id, 'inputModuleKey', event.target.value)}
              size="large"
              className="module-builder-inputs-container-textfield"
            />
          </div>
        ) : ''}
      <TextField
        label="Default For Input"
        variant="outlined"
        value={defaultValue}
        onChange={(event) => setModuleInputProperty(id, 'defaultValue', event.target.value)}
        size="large"
        className="module-builder-inputs-container-textfield"
      />
      {['singleSelectDropDown', 'multiSelectDropDown', 'toggle'].includes(inputType) ? (
        <TextField
          label="Possible Values. Enter Stringified JSON as input"
          variant="outlined"
          value={possibleValues}
          onChange={(event) => setModuleInputProperty(id, 'possibleValues', event.target.value)}
          size="large"
          className="module-builder-inputs-container-textfield"
        />
      ) : ''}
      {['singleSelectDropDown'].includes(inputType) ? (
        <TextField
          label="Possible Values for Labels. Enter Stringified JSON as input"
          variant="outlined"
          value={possibleValuesLabels}
          onChange={(event) => setModuleInputProperty(id, 'possibleValuesLabels', event.target.value)}
          size="large"
          className="module-builder-inputs-container-textfield"
        />
      ) : ''}
      {['singleSelectDropDown', 'multiSelectDropDown'].includes(inputType) ? (
        <TextField
          label="Input Title"
          variant="outlined"
          value={inputTitle}
          onChange={(event) => setModuleInputProperty(id, 'inputTitle', event.target.value)}
          size="large"
          className="module-builder-inputs-container-textfield"
        />
      ) : ''}
      {inputType === 'textbox' ? (
        <>
          <TextField
            label="Regex Validation"
            variant="outlined"
            value={regexForTextBox}
            onChange={(event) => setModuleInputProperty(id, 'regexForTextBox', event.target.value)}
            size="large"
            className="module-builder-inputs-container-textfield"
          />
          <TextField
            label="Error Message"
            variant="outlined"
            value={errorMessageForTextBox}
            onChange={(event) => setModuleInputProperty(id, 'errorMessageForTextBox', event.target.value)}
            size="large"
            className="module-builder-inputs-container-textfield"
          />
          <TextField
            label="placeholderForTextBox"
            variant="outlined"
            value={placeholderForTextBox}
            onChange={(event) => setModuleInputProperty(id, 'placeholderForTextBox', event.target.value)}
            size="large"
            className="module-builder-inputs-container-textfield"
          />
        </>
      ) : ''}
      {!isBuilderPropertiesSection ? (

        <div className="module-builder-inputs-container-dropdown">
          <ValidationDrawer
            defaultValue={{ type: 'rule', value: visible }}
            hideRegexTab
            onChange={(val) => setModuleInputProperty(id, 'visible', val.value)}
            dropDownOptions={parsedDropDownOptions}
            hideErrorMessageInput
          />
        </div>
      ) : ''}
      <div className="module-builder-inputs-container-dropdown">
        <NestedDropdownWithInputs
          defaultValue={required}
          items={{
            items: [{
              label: 'yes',
              value: 'yes',
            },
            {
              label: 'no',
              value: 'no',
            },
            ],
          }}
          noSelectLabel="Input Config Required"
          onChange={(selectedItem) => setModuleInputProperty(id, 'required', selectedItem.value)}
        />
      </div>
    </div>
  );
}

ModuleInputsEditPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setModuleInputProperty: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  inputModuleKey: PropTypes.string,
  defaultValue: PropTypes.string,
  possibleValues: PropTypes.string,
  regexForTextBox: PropTypes.string,
  errorMessageForTextBox: PropTypes.string,
  placeholderForTextBox: PropTypes.string,
  required: PropTypes.string,
  isBuilderPropertiesSection: PropTypes.bool,
  visible: PropTypes.string,
  moduleBuilderProperties: PropTypes.array.isRequired,
  moduleIdsDropdown: PropTypes.object,
  inputModuleId: PropTypes.string,
  possibleValuesLabels: PropTypes.string,
  inputTitle: PropTypes.string,
};

ModuleInputsEditPanel.defaultProps = {
  inputType: null,
  description: '',
  category: null,
  inputModuleKey: '',
  possibleValues: '',
  regexForTextBox: '',
  errorMessageForTextBox: '',
  placeholderForTextBox: '',
  required: null,
  defaultValue: '',
  isBuilderPropertiesSection: false,
  visible: null,
  moduleIdsDropdown: { items: [] },
  inputModuleId: '',
  possibleValuesLabels: '',
  inputTitle: '',
};

export default ModuleInputsEditPanel;
