import { useState } from 'react';
import PropTypes from 'prop-types';
import ModuleInputRow from './ModuleInputRow';
import generateUniqueID from '../../utils/generateUniqueId';
import ModuleOutputsEditPanel from './ModuleOutputsEditPanel';

function ModuleOutput({
  moduleOutputs,
  onAddModuleOutput,
  onSaveModuleOutput,
  dropdownOptions,
}) {
  const [showOutputDetailPanel, setShowOutputDetailPanel] = useState(false);
  const [currentSelectedOutput, setCurrentSelectedOutput] = useState({});

  const addModuleOutput = () => {
    onAddModuleOutput({ name: `Output ${generateUniqueID()}` });
  };

  const onClickExistingInput = (id) => {
    if (showOutputDetailPanel) {
      setShowOutputDetailPanel(false);
      setCurrentSelectedOutput({});
    } else {
      const selectedModuleOutput = moduleOutputs.find((input) => input.id === id);
      setCurrentSelectedOutput({ ...selectedModuleOutput });
      setShowOutputDetailPanel(true);
    }
  };

  const onSave = () => {
    setShowOutputDetailPanel(false);
    onSaveModuleOutput(currentSelectedOutput.id, currentSelectedOutput);
    setCurrentSelectedOutput({});
  };

  const setModuleOutputProperty = (id, property, value) => {
    const oldSelectedOutputValues = { ...currentSelectedOutput };
    oldSelectedOutputValues[property] = value;
    setCurrentSelectedOutput(oldSelectedOutputValues);
  };

  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">Module Outputs</div>
        <div className="workflowInput__topContent_subheading">
          If you want to get outputs from the module, please configure the SDK response below
        </div>
      </div>
      <div className="workflowInput__bottomContent">
        <button onClick={() => addModuleOutput()} type="button"> Add Module Output </button>
        {moduleOutputs.map((moduleOutput) => (
          <ModuleInputRow
            id={moduleOutput.id}
            name={moduleOutput.name}
            onClick={(id) => onClickExistingInput(id)}
            onDelete={() => {}}
            key={moduleOutput.id}
          />
        ))}
        {showOutputDetailPanel ? (
          <>
            <ModuleOutputsEditPanel
              id={currentSelectedOutput.id}
              name={currentSelectedOutput.name}
              description={currentSelectedOutput.description}
              setModuleOutputProperty={setModuleOutputProperty}
              dropDownOptions={dropdownOptions}
              selectedValue={currentSelectedOutput.selectedValue}
            />
            <button type="button" onClick={() => onSave(currentSelectedOutput)}> Save </button>
          </>
        ) : ''}
      </div>
    </>
  );
}

ModuleOutput.propTypes = {
  moduleOutputs: PropTypes.array.isRequired,
  onAddModuleOutput: PropTypes.func.isRequired,
  onSaveModuleOutput: PropTypes.func.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
};

export default ModuleOutput;
