import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import { fetchCurrentValueFromWorkflow, getSelectedModule, setModulePropertyInWorkflow } from '../InputsToModule/utils/updateWorkflow';
import { selectSelectedNode, selectSelectedWorkflow, selectModules } from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';

function TextInputWrapper({ element }) {
  const {
    workflowKey, text: label, errortext: errorText, placeholder, regex, default: defaultValue,
  } = element;

  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const selectedNodeType = useSelector(selectSelectedNode)?.nodeType;
  const selectedModuleConfig = useSelector(selectModules)[selectedNodeType];

  const defaultInput = useMemo(() => {
    const selectedModule = getSelectedModule(selectedWorkflow, selectedNodeId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      workflowKey,
    );
    const valueFetchedFromWorkflow = typeof currentValue === 'string';
    const fallbackValue = typeof defaultValue === 'string' ? defaultValue : null;
    const value = valueFetchedFromWorkflow ? currentValue : fallbackValue;
    return value;
  }, [defaultValue, selectedModuleConfig, selectedNodeId, selectedWorkflow, workflowKey]);

  const onSave = (text) => {
    const editedWorkflow = setModulePropertyInWorkflow(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      text,
      selectedModuleConfig,
    );
    updateWorkflowInState(editedWorkflow);
  };

  return (
    <div className="text-input-wrapper">
      <TextInput
        defaultInput={defaultInput}
        label={label}
        errorText={errorText}
        placeholder={placeholder}
        regex={regex}
        onSave={onSave}
      />
    </div>
  );
}

TextInputWrapper.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TextInputWrapper;
