import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './reducers/user';
import alertReducer from './reducers/alert';
import workflowReducer from './reducers/workflow';
import onboardReducer from './reducers/onboard';
import inputsToModuleReducer from './reducers/inputsToModule';
import editBrandingReducer from './reducers/editBranding';
import dynamicFormReducer from './reducers/dynamicForm';
import logsReducer from './reducers/logs';
import moduleBuilderReducer from './reducers/moduleBuilder';

const combinedReducer = combineReducers({
  user: userReducer,
  alert: alertReducer,
  workflow: workflowReducer,
  onboard: onboardReducer,
  inputsToModule: inputsToModuleReducer,
  editBranding: editBrandingReducer,
  dynamicForm: dynamicFormReducer,
  logs: logsReducer,
  moduleBuilder: moduleBuilderReducer,
});

const persistConfig = { key: 'root', storage, blacklist: ['initialize'] };

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'RESET') { // check for action type
    newState = undefined;
  }
  return combinedReducer(newState, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// const resetBrandingReducer = (state, action) => {
//   const newState = state;
//   if (action.type === 'RESET_BRANDING') { // check for action type
//     newState.editBranding = undefined;
//   }
//   return combinedReducer(newState, action);
// };

const store = configureStore({
  reducer: persistedReducer,
  // reducer1: resetBrandingReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
const persistor = persistStore(store);
// persistor.purge(); // Used to clear persist storage.

export { persistor };
export default store;
