import { checkComponentForNextStep, getNextStepForModule } from '../components/utils';
import { getAllFormComponents } from '../containers/FormModule/helper';

// TODO: Update the test cases for this
export const isNextStepPresentInDynamicForm = (workflow) => {
  const isEveryModuleValid = workflow.modules.every((module) => {
    if (module.type === 'dynamicForm') {
      const formComponents = getAllFormComponents(module);
      return formComponents.some((component) => checkComponentForNextStep(component));
    }

    return module.nextStep !== '';
  });

  return isEveryModuleValid;
};

export const isNextStepOfNodeNotPointingToItSelf = (workflow) => {
  const { conditions = {}, modules } = workflow;

  const selfReferencingConditionsPresent = Object.keys(conditions).some((conditionId) => {
    const { if_true: ifTrueId, if_false: ifFalseId } = conditions[conditionId];
    return (conditionId === ifFalseId || conditionId === ifTrueId);
  });
  if (selfReferencingConditionsPresent) return false;

  const selfReferencingModulesPresent = modules.some((module) => {
    const nextSteps = getNextStepForModule(module);
    const anyNextStepPointingToSelf = nextSteps.some(({ nextStep }) => nextStep === module.id);
    return anyNextStepPointingToSelf;
  });
  if (selfReferencingModulesPresent) return false;
  return true;
};
