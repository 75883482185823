/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import './Container.scss';
import { updateUserInfo, updateAppIdKeyToken } from '../reducers/user';
import { updateModules } from '../reducers/workflow';
import { toggleModuleBuilderMode } from '../reducers/moduleBuilder';
import AlertCode from '../constants/alertCodes';
import CustomWorkflowAlert from '../components/Common/CustomWorkflowAlert';
import useApiHooks from '../utils/useApiHooks';
import useShowErrorAlert from '../utils/customHooks';

axios.defaults.withCredentials = true;

function Main() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [moduleConfigs, setModuleConfigs] = useState({});
  const showErrorAlert = useShowErrorAlert();
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const {
    fetchModules, fetchV2Modules, fetchGlobalConfig, fetchDocToken, fetchGoogleFontsList,
  } = useApiHooks();

  const fetchUser = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user`,
      });
      dispatch(updateUserInfo(userRes.data.result));
      return userRes;
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchUser });
      return null;
    }
  };

  const fetchAppIdToken = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        headers: { appid: currentAppId || '55a3a6' },
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/demoToken`,
      });
      const { token } = userRes.data.result;
      dispatch(updateAppIdKeyToken({ token }));
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchUser });
    }
  };

  useEffect(() => {
    const startApp = async () => {
      setIsLoading(true);
      fetchDocToken();
      const userResponse = await fetchUser();
      await fetchAppIdToken();
      const v1Modules = await fetchModules();
      if (v1Modules) {
        setModuleConfigs(v1Modules);
      }
      const v2Modules = await fetchV2Modules();
      if (v2Modules) {
        const updatedModules = { ...v1Modules, ...v2Modules };
        dispatch(updateModules({ modules: updatedModules }));
      }
      if (userResponse?.data?.result?.clientId === process.env.REACT_APP_MODULE_BUILDER_CLIENT_ID) {
        dispatch(toggleModuleBuilderMode({ mode: true }));
      }
      await fetchGoogleFontsList();
      fetchGlobalConfig();
      setIsLoading(false);
    };
    startApp();
  }, []);

  return (
    <>
      {!isLoading && (
        <>
          <Outlet />
          <CustomWorkflowAlert />
        </>
      )}
      {isLoading && (<div className="reload-loading">loading please wait</div>)}
    </>
  );
}

export default Main;
