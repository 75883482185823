/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { eventHandlers, endStates } from './constants';
import generateUniqueID from '../utils/generateUniqueId';
import { getAllFormComponents, getAllNextSteps } from '../containers/FormModule/helper';
import { updateFormPropertyViaComponentId } from './FormModule/utils';

const conditionMap = {
  '!=': 'is not',
  '==': 'is',
};

const betweenMap = {
  '||': 'or',
  '&&': 'and',
};
const betweenRegex = /(&&|\|\|)/;
const conditonRegex = /(!=|==)/;
const paranthsisRegex = /\((.*?)\)/;

const arrayToJson = (arr) => {
  const obj = {};
  arr.forEach((element) => {
    obj[element.id] = element;
  });
  return obj;
};

// parses a string and returns paranthesis blocks
const paranthesisParser = (str) => {
  const stack = [];
  let startIndex = 0;
  const paranthesisMap = {};
  for (let i = 0; i < str.length; i += 1) {
    if (str[i] === '(') {
      if (stack.length === 0) {
        startIndex = i;
      }
      stack.push(str[i]);
    } else if (str[i] === ')') {
      stack.pop();
      if (stack.length === 0) {
        const param = {
          old: str.slice(startIndex + 1, i),
          new: str.slice(startIndex, i + 1),
        };
        const key = `${startIndex}_${i + 1}`;
        paranthesisMap[key] = param;
      }
    }
  }
  return paranthesisMap;
};
export const checkComponentForNextStep = (component) => (
  eventHandlers.some(
    (eventHandler) => component[eventHandler]?.nextStep && component[eventHandler].nextStep !== '',
  ) ||
    (component.subComponents && component.subComponents.some(checkComponentForNextStep))
);
const recursiveFetchRules = (arr, modules) => {
  const newArr = [];
  let paranthesisMap;
  arr.forEach((item) => {
    // check if paranthesis exists
    // replace paranthesis with a simpler key
    if (paranthsisRegex.test(item)) {
      paranthesisMap = paranthesisParser(item, modules);
      Object.keys(paranthesisMap).forEach((key) => {
        item = item.replace(paranthesisMap[key].new, key);
      });
    }
    // check if string is && or ||
    if (item in betweenMap) {
      newArr.push(betweenMap[item]);
    } else if (item in conditionMap) { // check if string is != or ==
      newArr.push(conditionMap[item]);
    } else if (betweenRegex.test(item)) { // check if || or && is part of string
      // split the string on if || or &&
      const paramSplit = item.split(betweenRegex).filter(Boolean).map((it) => it.trim());
      // check if split strings are part of simplified paranthesis map
      // if so, substitute them with child recursiveFetchModules
      // else append child recursiveFetchModules
      if (paranthesisMap) {
        for (let i = 0; i < paramSplit.length; i += 1) {
          if (paramSplit[i] in paranthesisMap) {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paranthesisMap[paramSplit[i]].old], modules);
          } else {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paramSplit[i]], modules)[0];
          }
        }
        newArr.push(...paramSplit);
      } else {
        newArr.push(...recursiveFetchRules(paramSplit, modules));
      }
    } else if (conditonRegex.test(item)) { // check if != or == is part of string
      // split the string on if != or ==
      const paramSplit = item.split(conditonRegex).filter(Boolean);
      // check if split strings are part of simplified paranthesis map
      // if so, substitute them with child recursiveFetchModules
      // else append child recursiveFetchModules
      if (paranthesisMap) {
        for (let i = 0; i < paramSplit.length; i += 1) {
          if (paramSplit[i] in paranthesisMap) {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paranthesisMap[paramSplit[i]].old], modules);
          } else {
            // eslint-disable-next-line prefer-destructuring
            paramSplit[i] = recursiveFetchRules([paramSplit[i]], modules)[0];
          }
        }
        newArr.push(paramSplit);
      } else {
        newArr.push(recursiveFetchRules(paramSplit, modules));
      }
    } else if (paranthesisMap && item in paranthesisMap) {
      newArr.push(recursiveFetchRules([paranthesisMap[item].old], modules));
    } else {
      // check if key has a module Ex: module1.statusCode
      const [moduleId, key] = item.split('.');
      if (!moduleId || !key) {
        newArr.push(_.startCase(item));
      } else {
        modules.forEach((mod) => {
          if (mod.id === moduleId) {
            newArr.push(`${_.startCase(key)} From ${_.startCase(mod.subType)}`);
          }
        });
      }
    }
  });
  return newArr;
};

// TODO: Use event handlers here
export const getNextStepFromComponents = (components, pathFromParent) => {
  const nextSteps = [];
  (components || []).forEach((component, index) => {
    if (
      Array.isArray(component?.dynamicHandlers?.handlers)) {
      component?.dynamicHandlers?.handlers.forEach((handler) => {
        if (handler?.nextStep) { nextSteps.push({ nextStep: handler.nextStep }); }
      });
    }

    if (component?.onChange?.nextStep) nextSteps.push({ nextStep: component?.onChange?.nextStep, path: `${pathFromParent}${pathFromParent.length ? ':' : ''}${index}`, key: 'onChange' });
    if (component?.onComplete?.nextStep) nextSteps.push({ nextStep: component?.onComplete?.nextStep, path: `${pathFromParent}${pathFromParent.length ? ':' : ''}${index}`, key: 'onComplete' });
    if (component?.onValidated?.nextStep) nextSteps.push({ nextStep: component?.onValidated?.nextStep, path: `${pathFromParent}${pathFromParent.length ? ':' : ''}${index}`, key: 'onValidated' });
    if (component?.onClick?.nextStep) nextSteps.push({ nextStep: component?.onClick?.nextStep, path: `${pathFromParent}${pathFromParent.length ? ':' : ''}${index}`, key: 'onClick' });
    if (component?.subComponents?.length) {
      const { subComponents } = component;
      const subNextSteps = getNextStepFromComponents(subComponents, `${pathFromParent}${pathFromParent.length ? ':' : ''}${index}`);
      nextSteps.push(...subNextSteps);
    }
  });
  return nextSteps;
};

export const updateNextStepForModule = (module, parentPath, newNextStep, newNextNodeType = '') => {
  if (module.type === 'dynamicForm') {
    const [componentId, ...nextStepPathArray] = parentPath.split('.');
    const nextStepPath = nextStepPathArray.join('.');
    const updatedModule =
      updateFormPropertyViaComponentId(module, componentId, nextStepPath, newNextStep);
    const existingNextNodeType = module.next_node_type || {};
    updatedModule.next_node_type = {
      ...existingNextNodeType,
      [parentPath]: newNextNodeType,
    };
    return updatedModule;
  }
  module.nextStep = newNextStep;
  module.next_node_type = {
    default: newNextNodeType,
  };
  return module;
};

export const getNextStepForModule = (module) => {
  if (module.type === 'dynamicForm') {
    const allComponents = getAllFormComponents(module);
    const includeNextStepsFromDynamicHandlers = true;
    const rawNextSteps = getAllNextSteps(
      allComponents,
      eventHandlers,
      includeNextStepsFromDynamicHandlers,
    );
    const nextSteps = rawNextSteps.map(({ nextStepId, componentId, nextStepEvent }) => ({
      nextStep: nextStepId,
      path: `${componentId}.${nextStepEvent}.nextStep`,
      key: nextStepEvent,
    }));
    return nextSteps;
  }
  return [{ nextStep: module.nextStep, path: 'default' }];
};

const getNameForId = (id, modulesJson, conditions) => {
  if (modulesJson[id]?.name) return modulesJson[id].name;
  if (conditions[id]?.name) return conditions[id]?.name;
  return id;
};

// BFS Algorithm to recursively convert the config into nodes and edges
export default function convertToNodesEdges(config) {
  const { modules, conditions } = config;
  const nodes = [];
  const edges = [];
  if (!modules) return { nodes, edges };
  const modulesJson = arrayToJson(modules);
  let isEndStateReachable = false;
  const start = {
    id: 'start',
    type: 'startNode',
    subType: 'start',
    nextStep: modules[0].id,
    name: 'start',
  };
  const queue = [{ ...start, x: 0, level: 0 }];
  const completedNodeIds = [];
  while (queue.length > 0) {
    const module = queue.shift();
    const {
      id, type, parentBranch, superModuleId, parentId, actualNode, name, parentPath, nextStepFrom,
    } = module;
    // eslint-disable-next-line no-continue
    if (completedNodeIds.includes(id)) continue;
    completedNodeIds.push(id);
    const nextSteps = getNextStepForModule(module);
    // check if module is condition
    if (type === 'condition') {
      const { if_true, if_false, rule } = module;
      const ruleArr = recursiveFetchRules([rule], modules);
      let passNodeId; let failNodeId;
      const conditionData = {
        ruleArr,
      };
      nodes.push({
        id,
        nodeType: module.subType,
        superModuleId,
        name: module.name || id,
        parentId,
        ...conditionData,
        parentPath,
        parentBranch,
      });
      const parent = id;
      if (module.next_node_type && module.next_node_type.if_false === 'goto') {
        failNodeId = `goto.${id}.if_false.${if_false}`;
        queue.push({
          id: failNodeId, type: 'termination', subType: 'goto', nextStep: null, parentBranch: 'if_false', parentId: parent, actualNode: if_false, name: getNameForId(if_false, modulesJson, conditions),
        });
      } else if (conditions[if_false]) {
        failNodeId = if_false;
        queue.push({
          ...conditions[if_false], id: failNodeId, type: 'condition', subType: 'condition', parentId: parent, parentBranch: 'if_false',
        });
      } else if (modulesJson[if_false]) {
        failNodeId = modulesJson[if_false].id;
        queue.push({
          ...modulesJson[if_false], id: failNodeId, parentId: parent, parentBranch: 'if_false',
        });
      } else {
        failNodeId = `${if_false}.${id}.if_false`;
        queue.push({
          id: failNodeId, type: 'termination', subType: if_false, nextStep: null, parentBranch: 'if_false', parentId: parent,
        });
      }

      if (module.next_node_type && module.next_node_type.if_true === 'goto') {
        passNodeId = `goto.${id}.if_true.${if_true}`;
        queue.push({
          id: passNodeId, type: 'termination', subType: 'goto', nextStep: null, parentBranch: 'if_true', parentId: parent, actualNode: if_true, name: getNameForId(if_true, modulesJson, conditions),
        });
      } else if (conditions[if_true]) {
        passNodeId = if_true;
        queue.push({
          ...conditions[if_true], id: passNodeId, type: 'condition', subType: 'condition', parentId: parent, parentBranch: 'if_true',
        });
      } else if (modulesJson[if_true]) {
        passNodeId = modulesJson[if_true].id;
        queue.push({
          ...modulesJson[if_true], id: passNodeId, parentId: parent, parentBranch: 'if_true',
        });
      } else {
        passNodeId = `${if_true}.${id}.if_true`;
        queue.push({
          id: passNodeId, type: 'termination', subType: if_true, nextStep: null, parentBranch: 'if_true', parentId: parent,
        });
      }

      edges.push({
        id: `${id}_${passNodeId}`, source: id, target: passNodeId, type: 'conditionEdge', animated: false, data: { branch: 'If true' },
      }, {
        id: `${id}_${failNodeId}`, source: id, target: failNodeId, type: 'conditionEdge', animated: false, data: { branch: 'If false' },
      });
    } else if (type === 'termination') {
      if (module.subType !== 'goto') isEndStateReachable = true;
      nodes.push({
        id,
        nodeType: module.subType,
        superModuleId,
        parentBranch,
        parentId,
        actualNode,
        name,
        parentPath,
        nextStepFrom,
      });
    } else {
      nodes.push({
        nodeType: module.subType, id, superModuleId, parentId, name, parentPath, parentBranch,
      });
      nextSteps.forEach(({ nextStep, path, key }) => {
        let targetNodeId;
        const parent = id;
        if (module?.next_node_type?.[path] === 'goto') {
          targetNodeId = `goto.${id}.${nextStep}.${path}`;
          queue.push({
            id: targetNodeId, type: 'termination', subType: 'goto', nextStep: null, parentId: parent, actualNode: nextStep, parentPath: path, name: getNameForId(nextStep, modulesJson, conditions), nextStepFrom: key,
          });
        } else if (modulesJson[nextStep]) {
          targetNodeId = modulesJson[nextStep].id;
          queue.push({
            ...modulesJson[nextStep], id: targetNodeId, parentId: parent, parentPath: path,
          });
        } else if (conditions[nextStep]) {
          targetNodeId = nextStep;
          queue.push({
            ...conditions[nextStep], id: targetNodeId, type: 'condition', subType: 'condition', parentId: parent, parentPath: path,
          });
        } else {
          const uid = generateUniqueID();
          targetNodeId = `${nextStep}_${id}_${uid}`;
          queue.push({
            id: targetNodeId, type: 'termination', subType: nextStep, nextStep: null, parentId: parent, parentPath: path, nextStepFrom: key,
          });
        }
        if (conditions[nextStep]) {
          edges.push({
            id: `${id}_${targetNodeId}`, source: id, target: targetNodeId, targetHandle: 'topHandle', sourceHandle: 'moduleBottom', type: 'moduleEdge', animated: false,
          });
        } else {
          edges.push({
            id: `${id}_${targetNodeId}`, source: id, target: targetNodeId, type: 'moduleEdge', animated: false,
          });
        }
      });
    }
  }
  return { nodes, edges, isEndStateReachable };
}

// Convert nodes, edges to config
const convertToConfig = ({ nodes, edges }) => {
  const nodesJson = arrayToJson(nodes);

  const modules = [];
  const conditions = {};

  nodes.forEach((node) => {
    const { id, position, nodeType } = node;

    const module = {
      subType: nodeType, id, nextStep: '',
    };

    const targets = [];

    // Get targets for the source
    edges.forEach((edge) => {
      if (edge.source === id) targets.push(edge.target);
    });

    // Two targets - Condition
    if (targets.length === 2) {
      const node1 = nodesJson[targets[0]];
      const node2 = nodesJson[targets[1]];
      let if_true = node1.id;
      let if_false = node2.id;

      // If node1 is not in same x-coordinate, then it is the if_false node
      if (node1.position.x !== position.x) {
        if_true = node2.id;
        if_false = node1.id;
      }
      const conditionKey = `condition_${Object.keys(conditions).length}`;
      conditions[conditionKey] = {
        if_true,
        if_false,
      };
      module.nextStep = conditionKey;
    } else {
      [module.nextStep] = targets;
    }

    // Only add modules
    if (!Object.keys(endStates).includes(nodeType)) modules.push(module);
  });

  // Clean module names
  modules.forEach((module) => {
    // eslint-disable-next-line no-param-reassign
    if (typeof module.nextStep === 'string') {
      const temp = module.nextStep.split('_')[0];
      if (Object.keys(endStates).includes(temp)) module.nextStep = temp;
    }
  });

  // Clean edge names
  Object.entries(conditions).forEach(([, value]) => {
    const { if_true, if_false } = value;
    let temp = if_true.split('_')[0];
    if (Object.keys(endStates).includes(temp)) {
      value.if_true = temp;
    }
    [temp] = if_false.split('_');
    if (Object.keys(endStates).includes(temp)) value.if_false = temp;
  });

  return { modules, conditions };
};

const closeExistingSDK = () => {
  // ref HTML collection: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCollection
  const sdk = Array.from(document.getElementsByClassName('hv-modal'));
  sdk.forEach((modal) => {
    modal.remove();
  });
  if (window.hyperSnapSDKObject.stream) {
    window.hyperSnapSDKObject.stream.getTracks().forEach((track) => {
      track.stop();
    });
  }
};

export {
  convertToConfig, closeExistingSDK,
};
