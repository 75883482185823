import PropTypes from 'prop-types';
import { Button } from '@mui/base';
import { useEffect, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TryOnMobile from './TryOnMobile';
import TryOnWeb from './TryOnWeb';
import '../Container.scss';

function TryWorkflow({ workflowId }) {
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="try-workflow" ref={containerRef}>
      <Button className="try-workflow__btn" onClick={() => setShowMenu((value) => !value)}>
        <span className="try-workflow__btn-text">Try Workflow</span>
        <ExpandMoreIcon className="try-workflow__chevron-down-icon" />
      </Button>
      {showMenu && (
        <div className="try-workflow__options">
          <TryOnWeb workflowId={workflowId} />
          <TryOnMobile />
        </div>
      )}
    </div>
  );
}

TryWorkflow.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

export default TryWorkflow;
