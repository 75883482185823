import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

function DropDown(props) {
  const {
    label,
    onChange,
    defaultValue,
    options,
    placeholder,
  } = props;

  const handleOptionSelect = (e) => {
    onChange(e.target.value);
  };

  return (
    <Grid container alignItems="center" className="edit-properties-div__dropdown-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </Grid>
      <Grid item xs={8}>
        <select className="edit-properties-div__dropdown" onChange={handleOptionSelect}>
          <option
            value=""
            selected={defaultValue}
            disabled={!!defaultValue}
          >
            {placeholder}
          </option>
          {
            options && options.map((option) => (
              <option key={option.key} value={option.key} selected={option.key === defaultValue}>
                {option.name}
              </option>
            ))
            }
        </select>
      </Grid>
    </Grid>
  );
}

DropDown.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

DropDown.defaultProps = {
  placeholder: 'Please select',
};

export default DropDown;
