import { cloneDeep, get, set } from 'lodash';
import { eventHandlers } from '../../components/constants';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import { formComponentsBasePath } from '../../reducers/dynamicForm';
import { extractComponentIdsForModule } from '../../utils/helper';

export const getFormComponents = (module, rootPath = 'components') => get(module?.properties?.sections?.[0], rootPath, []);

export const getComponentFromPath = (components, pathArray) => {
  if (!components?.length || !pathArray?.length) return null;
  if (pathArray.length === 1) return components[pathArray[0]] || null;
  const [currentIndex, ...newPathArray] = pathArray;
  const childComponents = components[currentIndex]?.subComponents;
  return getComponentFromPath(childComponents, newPathArray);
};

export const getSelectedComponent = (module, pathArray, rootPath = 'components') => {
  const components = getFormComponents(module, rootPath);
  if (!components?.length) return null;
  const finalComponent = getComponentFromPath(components, pathArray);
  return finalComponent;
};

const allowedOperations = ['add', 'delete', 'insert', 'update'];

export const operateOnFormComponents = (operation, components, pathArray, newComponent = null) => {
  if (
    !allowedOperations.includes(operation) ||
    typeof components?.length !== 'number' ||
    typeof pathArray?.length !== 'number'
  ) {
    return components;
  }

  const clonnedComponents = cloneDeep(components || []);
  if (pathArray.length === 0 && operation === 'add') {
    clonnedComponents.push(newComponent);
    return clonnedComponents;
  }
  if (pathArray.length === 1 && ['delete', 'insert', 'update'].includes(operation)) {
    if (operation === 'insert') clonnedComponents.splice([pathArray[0]], 0, newComponent);
    if (clonnedComponents?.length > pathArray[0]) {
      if (operation === 'delete') clonnedComponents.splice(pathArray[0], 1);
      if (operation === 'update') clonnedComponents[pathArray[0]] = newComponent;
    }
    return clonnedComponents;
  }
  const [currentIndex, ...newPath] = pathArray;
  const currentSubComponents = clonnedComponents[currentIndex]?.subComponents;
  if (!currentSubComponents) return clonnedComponents;
  const newChildSubComponents = operateOnFormComponents(
    operation,
    currentSubComponents,
    newPath,
    newComponent,
  );
  clonnedComponents[currentIndex].subComponents = newChildSubComponents;
  return clonnedComponents;
};

export const setComponentsArrayAtRootPath = (components, rootPath, module) => {
  const clonnedModule = cloneDeep(module);
  set(clonnedModule.properties.sections[0], rootPath, components);
  return clonnedModule;
};

export const performOpOnWorkflow = (
  operation,
  workflow,
  moduleId,
  pathArray,
  rootPath = 'components',
  newComponent = null,
) => {
  if (!allowedOperations.includes(operation)) return workflow;
  const editedWorkflow = cloneDeep(workflow);
  const selectedModule = getSelectedModule(editedWorkflow, moduleId);
  if (!selectedModule) return editedWorkflow;
  const components = get(selectedModule?.properties?.sections[0], rootPath, []);
  const updatedComponents = operateOnFormComponents(operation, components, pathArray, newComponent);
  set(selectedModule.properties.sections[0], rootPath, updatedComponents);
  return editedWorkflow;
};

// TODO: Deprecate ?
export const getTotalBranches = (components) => {
  let totalBranches = 0;
  (components || []).forEach((component) => {
    eventHandlers.forEach((nextStepEvent) => {
      if (component?.[nextStepEvent]?.nextStep) totalBranches += 1;
    });
    if (component?.subComponents?.length) {
      const totalSubBranches = getTotalBranches(component.subComponents || []);
      totalBranches += totalSubBranches;
    }
  });
  return totalBranches;
};

export const getAllFormComponentsObj = (module) => {
  const basePaths = Object.keys(formComponentsBasePath);
  const componentsObj = {};
  basePaths.forEach((basePath) => {
    componentsObj[basePath] = getFormComponents(module, formComponentsBasePath[basePath]);
  });
  return componentsObj;
};

export const getAllFormComponents = (module) => {
  const allComponentsObj = getAllFormComponentsObj(module);
  const allComponents = Object.values(allComponentsObj).flat();
  return allComponents;
};

export const getAllNextSteps = (
  components,
  nextStepEvents = [],
  includeDynamicHandlers = false,
) => {
  const nextSteps = [];
  (components || []).forEach((component) => {
    if (includeDynamicHandlers && Array.isArray(component?.dynamicHandlers?.handlers)) {
      component?.dynamicHandlers?.handlers.forEach((handler) => {
        if (handler?.nextStep) {
          nextSteps.push({
            nextStepId: handler.nextStep,
            componentId: component?.id,
          });
        }
      });
    }

    nextStepEvents.forEach((nextStepEvent) => {
      if (component?.[nextStepEvent]?.nextStep) {
        nextSteps.push({
          nextStepId: component?.[nextStepEvent]?.nextStep,
          componentId: component?.id,
          nextStepEvent,
        });
      }
    });
    if (component?.subComponents?.length) {
      const subNextSteps = getAllNextSteps(
        component.subComponents || [],
        nextStepEvents,
        includeDynamicHandlers,
      );
      nextSteps.push(...subNextSteps);
    }
  });
  return nextSteps;
};

export const canDeleteComponent = (module, pathArray, rootPath) => {
  const components = getFormComponents(module, rootPath);
  const componentToBeDeleted = getComponentFromPath(components, pathArray);
  if (componentToBeDeleted?.type === 'button' && componentToBeDeleted?.onClick?.nextStep) {
    const allComponents = getAllFormComponents(module);
    const totalBranches = getTotalBranches(allComponents);
    return totalBranches > 1;
  }
  return true;
};

const generateRuleString = (component) => {
  let ruleString = ' ';
  ruleString += ` ${component?.required || ' '}`;
  ruleString += ` ${component?.visible || ' '}`;
  ruleString += ` ${component?.enabled || ' '}`;
  const rulesValidation = (component?.validation || []).filter(
    (validation) => validation?.type === 'rule',
  );
  ruleString += rulesValidation.reduce(
    (accumulator, currentValue) => accumulator + currentValue.value,
    ' ',
  );
  return ruleString;
};

const extractFieldsForComponent = (component, moduleId) => {
  const ruleString = generateRuleString(component);
  let fields = extractComponentIdsForModule(ruleString, moduleId);
  fields = fields.filter((field) => field !== component.id);
  return fields;
};

const getReloadInverseDependency = (components, moduleId) => {
  let dependency = {};
  if (!components?.length) return dependency;
  (components || []).forEach((component) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const subComponentDependency = getReloadInverseDependency(subComponents, moduleId);
        dependency = { ...dependency, ...subComponentDependency };
      }
    } else {
      const fields = extractFieldsForComponent(component, moduleId);
      dependency[component.id] = fields;
    }
  });
  return dependency;
};

const updateReloadDependency = (components, reloadDependency) => {
  if (!components?.length) return [];
  const clonnedComponents = cloneDeep(components);
  (clonnedComponents || []).forEach((component, index) => {
    if (component?.type === 'horizontal' || component?.type === 'vertical') {
      const subComponents = component?.subComponents || [];
      if (subComponents?.length) {
        const updatedSubComponents = updateReloadDependency(subComponents, reloadDependency);
        clonnedComponents[index].subComponents = updatedSubComponents;
      }
    } else {
      clonnedComponents[index].onChange = {
        reloadComponents: Object.keys(reloadDependency[component.id] || {}),
      };
    }
  });
  return clonnedComponents;
};

const invertDependency = (onReloadInverseDependency) => {
  const onReloadDependency = {};
  Object.keys(onReloadInverseDependency).forEach((dependency) => {
    const fields = onReloadInverseDependency[dependency];
    fields.forEach((field) => {
      const existingObj = onReloadDependency[field];
      onReloadDependency[field] = { [dependency]: 'present', ...(existingObj || {}) };
    });
  });
  return onReloadDependency;
};

const getReloadDependency = (components, moduleId) => {
  const onReloadInverseDependency = getReloadInverseDependency(components, moduleId);
  const onReloadDependency = invertDependency(onReloadInverseDependency);
  return onReloadDependency;
};

export const updateDependencyForOnReload = (components, moduleId) => {
  const onReloadDependency = getReloadDependency(components, moduleId);
  const updatedComponents = updateReloadDependency(components, onReloadDependency);
  return updatedComponents;
};

// TODO: Write tests for this
export const updateOnReloadDependencyForModule = (module) => {
  if (module.type !== 'dynamicForm') return module;
  const moduleId = module.id;
  const updatedModule = cloneDeep(module);
  const allComponentsObj = getAllFormComponentsObj(updatedModule);
  const basePaths = Object.keys(allComponentsObj);
  const allComponents = basePaths
    .map((basePath) => allComponentsObj[basePath])
    .reduce((acc, curr) => [...acc, ...curr]);

  const onReloadDependency = getReloadDependency(allComponents, moduleId);

  basePaths.forEach((basePathKey) => {
    const actualPathFromSection = formComponentsBasePath[basePathKey];
    const components = allComponentsObj[basePathKey];
    const updatedComponents = updateReloadDependency(components, onReloadDependency);
    set(updatedModule, `properties.sections[0].${actualPathFromSection}`, updatedComponents);
  });
  return updatedModule;
};

export const getdefaultUIValue = (currUiConfig, defaultUiArray, subType = '') => {
  if (!defaultUiArray?.length) return null;
  const defaultUiObjForSubType = defaultUiArray.find((obj) => obj?.subType === subType);
  if (!defaultUiObjForSubType) return null;
  const { key } = defaultUiObjForSubType;
  let uiValue;
  Object.entries(currUiConfig).forEach(([, sectionValue]) => {
    Object.entries(sectionValue).forEach(([innerKey, innerValue]) => {
      if (innerKey === key) uiValue = innerValue;
    });
  });
  return uiValue;
};
