import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import {
  cloneDeep,
  get,
  set,
  unset,
} from 'lodash';

import { findModulesAndConditions, getDefaultComponent, updateComponentProperties } from './utils';
import FormModuleGenericEditor from './FormModuleGenericEditor';
import CopyIcon from '../../assests/icons/contentCopyIcon.svg';
import DropDown from './Common/DropDown';
import downIcon from '../../assests/icons/dropDownIcon.svg';
import sideIcon from '../../assests/icons/sideIcon.svg';

function FormModuleEditProperties(props) {
  const {
    selectedModule,
    selectedComponent,
    formComponentsConfig,
    selectedModuleId,
    orderOfNodes,
    customUiConfig,
    handleOnComponentChange,
    supportedFonts,
    currentUiConfig,
    addnewFontURL,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    selectedWorkflowId,
    preDefinedValues,
  } = props;
  const [openDropdowns, setOpenDropdowns] = useState([]);

  const { type: selectedComponentType } = selectedComponent;
  const [copyAlert, setCopyAlert] = useState(false);
  const timerRef = useRef();

  const [componentSectionsObj, subTypeDropDown] = useMemo(() => {
    const fieldConfig = formComponentsConfig.find(
      (field) => field.type === selectedComponent.type,
    );
    const { brandingKeys = [], subType = null } = fieldConfig || {};
    const componentSections = brandingKeys.reduce((sectionsSoFar, currentBrandingKey) => ({
      ...sectionsSoFar,
      [currentBrandingKey.section.key]: {
        title: currentBrandingKey.section.name,
        fields: [
          ...(sectionsSoFar[currentBrandingKey.section.key]?.fields || []),
          currentBrandingKey,
        ],
      },
    }), {});
    return [componentSections, subType];
  }, [selectedComponent, formComponentsConfig]);

  useEffect(() => {
    setOpenDropdowns(
      (prevOpenDropdowns) => (prevOpenDropdowns.length === 0
        ? [Object.keys(componentSectionsObj)[0]] : prevOpenDropdowns),
    );
  }, [componentSectionsObj]);

  const setAlert = () => {
    clearTimeout(timerRef.current);
    setCopyAlert(true);
    timerRef.current = setTimeout(() => {
      setCopyAlert(false);
    }, 5000);
  };

  const handleFieldSelection = (newType, module, formConfig) => {
    const defaultConfig = formConfig.find((component) => component.type === newType);
    const componentToBeAdded = getDefaultComponent(defaultConfig, module);
    handleOnComponentChange(componentToBeAdded);
  };

  const getFieldOptions = (formConfig) => formConfig.map(
    (component) => ({ key: component.type, name: component.name }),
  );

  const getNextStepOptions = (allNodes) => {
    const nodes = findModulesAndConditions(allNodes);
    const optionsForNextStepSelection = nodes.map((node) => ({
      key: node.id,
      name: node.name || node.id,
    }));
    return optionsForNextStepSelection;
  };

  const handleComponentPropertyChange = (
    value,
    key,
    currentComponent,
    isUIProperty,
    currCustomUiConfig,
    moduleId,
  ) => {
    if (isUIProperty) {
      const path = `${moduleId}.${currentComponent.id}`;
      const editedComponentUiConfig = cloneDeep(get(currCustomUiConfig, path, {}));
      if (value === '') {
        unset(editedComponentUiConfig, key);
      } else {
        set(editedComponentUiConfig, key, value);
      }
      if (key === 'font') addnewFontURL(value);
      handleOnComponentChange(currentComponent, editedComponentUiConfig);
    } else {
      const editedComponent = updateComponentProperties(
        value,
        key,
        currentComponent,
      );
      const path = `${moduleId}.${currentComponent.id}`;
      const editedComponentUiConfig = cloneDeep(get(currCustomUiConfig, path, {}));
      handleOnComponentChange(editedComponent, editedComponentUiConfig);
    }
  };

  const handleOptionsChange = (options, currentComponent) => {
    const updatedItems = [];
    const updatedLabels = {};
    options.forEach((option) => {
      updatedItems.push(option.value);
      updatedLabels[option.value] = option.key;
    });

    let editedComponent = updateComponentProperties(
      updatedItems,
      'items',
      currentComponent,
    );
    editedComponent = updateComponentProperties(
      updatedLabels,
      'labels',
      editedComponent,
    );
    handleOnComponentChange(editedComponent);
  };

  const isDropdownOpen = (name) => (openDropdowns || []).find((dropdown) => dropdown === name);

  const onDropdownClick = (name) => {
    if (isDropdownOpen(name)) {
      setOpenDropdowns((currentOpenDropdowns) => currentOpenDropdowns
        .filter((dropdown) => dropdown !== name));
    } else {
      setOpenDropdowns((currentOpenDropdowns) => [...currentOpenDropdowns, name]);
    }
  };

  return (
    <div className="edit-properties-div">
      <div className="edit-properties-div__property">
        <Grid container alignItems="center" className="moduleId">
          <Grid item xs={4}>Module Id</Grid>
          <Grid item xs={8}>
            <div className="edit-properties-div__text-container">
              <input
                type="text"
                className="edit-properties-div__text-input"
                value={selectedModuleId}
                readOnly
              />
              <button
                type="button"
                className="edit-properties-div__text-copy"
              >
                <CopyToClipboard
                  text={selectedModuleId}
                  onCopy={setAlert}
                >
                  <img src={CopyIcon} alt="Copy" />
                </CopyToClipboard>
              </button>
            </div>
            {copyAlert && (
              <div className="edit-properties-div__alert">
                formId copied!
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      {selectedComponentType && (
        <div className="edit-properties-div__property">
          <DropDown
            label="Type"
            options={getFieldOptions(formComponentsConfig)}
            onChange={(newType) => {
              handleFieldSelection(newType, selectedModule, formComponentsConfig);
            }}
            defaultValue={selectedComponentType}
          />
        </div>
      )}
      {selectedComponentType && subTypeDropDown && (
        <div className="edit-properties-div__property">
          <DropDown
            onChange={(value) => handleComponentPropertyChange(
              value,
              subTypeDropDown.workflowKey,
              selectedComponent,
            )}
            defaultValue={
              selectedComponent[subTypeDropDown.workflowKey] || subTypeDropDown.defaultValue
            }
            label={subTypeDropDown.name}
            options={subTypeDropDown.values}
          />
        </div>
      )}
      {
        selectedComponentType &&
        Object.entries(componentSectionsObj || {}).map(([sectionKey, sectionBrandingKeys]) => (
          <div className="edit-properties-div__section" key={sectionKey}>
            <button type="button" className="edit-properties-div__title" onClick={() => onDropdownClick(sectionKey)}>
              {sectionBrandingKeys.title}
              {isDropdownOpen(sectionKey)
                ? <img src={downIcon} alt="down" />
                : <img src={sideIcon} alt="side" />}
            </button>
            { isDropdownOpen(sectionKey) && selectedComponentType && sectionBrandingKeys && (
              <FormModuleGenericEditor
                component={selectedComponent}
                handleComponentPropertyChange={
                  (value, key, currComponent, isUIProperty) => handleComponentPropertyChange(
                    value,
                    key,
                    currComponent,
                    isUIProperty,
                    customUiConfig,
                    selectedModuleId,
                  )
                }
                sectionBrandingKeys={sectionBrandingKeys.fields}
                nextStepOptions={getNextStepOptions(orderOfNodes)}
                customUiConfig={customUiConfig}
                moduleId={selectedModuleId}
                handleDropdownOptionsChange={handleOptionsChange}
                supportedFonts={supportedFonts}
                currentUiConfig={currentUiConfig}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
                selectedWorkflowId={selectedWorkflowId}
                preDefinedValues={preDefinedValues}
              />
            )}
          </div>
        ))
      }
    </div>
  );
}

export default FormModuleEditProperties;

FormModuleEditProperties.propTypes = {
  selectedComponent: PropTypes.object.isRequired,
  selectedModule: PropTypes.object.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
  orderOfNodes: PropTypes.array.isRequired,
  customUiConfig: PropTypes.object.isRequired,
  handleOnComponentChange: PropTypes.func.isRequired,
  supportedFonts: PropTypes.object.isRequired,
  currentUiConfig: PropTypes.object.isRequired,
  addnewFontURL: PropTypes.func.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
  selectedWorkflowId: PropTypes.string.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
};
