/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import kebabMenuIcon from '../../../assests/icons/kebabMenuIcon.svg';
import '../../../containers/Container.scss';
import DownloadWorkflow from './DownloadWorkflow';
import ConditionalVariables from './ConditionalVariablesButton';
import PublishToStaging from './PublishToStaging';

function MoreWorkflowOptions() {
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="more-options" ref={containerRef}>
        <button type="button" className="more-options__btn" onClick={() => setShowOptions((value) => !value)}>
          <img src={kebabMenuIcon} alt="Options" />
        </button>
        {showOptions && (
          <div className="more-options__options">
            <DownloadWorkflow />
            <ConditionalVariables />
            <PublishToStaging />
          </div>
        )}
      </div>
    </>
  );
}

export default MoreWorkflowOptions;
