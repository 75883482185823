import { truncate } from 'lodash';

export const getConditionalVariables = (conditionalVars) => (conditionalVars || []).map((cv) => ({
  label: truncate(cv.name, 20),
  value: `conditionalVariables.${cv.id}`,
  displayLabel: `Conditional Variables > ${cv.name}`,

}));

export const getWorkflowInputs = (wfInputs) => (wfInputs || []).map((wInp) => ({
  label: truncate(wInp.name, 20),
  value: `inputs.${wInp.id}`,
  displayLabel: `Inputs > ${wInp.name}`,
}));

export const getModuleOutputs = (outputs) => (outputs || []).map((output) => {
  const { variables, name: moduleLabel, id: moduleValue } = output;
  const outputVariables = (variables || []).map((variable) => ({
    label: variable.name,
    value: `${moduleValue}.${variable.id}`,
    displayLabel: `${moduleLabel} > ${variable.name}`,

  }));
  return {
    label: moduleLabel,
    items: outputVariables,
    disabled: outputVariables?.length === 0,
  };
});

export const createNestedMenuData = (
  workflowInputsData,
  conditionalVariablesData,
  moduleOutputsData,
) => {
  const moduleOutputItems = getModuleOutputs(moduleOutputsData);
  const conditionalVariableItems = getConditionalVariables(conditionalVariablesData);
  const workflowInputsItems = getWorkflowInputs(workflowInputsData);
  const predefinedValuesItems = [];

  const menuData = {
    label: 'Please Select',
    items: [
      {
        label: 'Module Outputs',
        items: moduleOutputItems,
        disabled: moduleOutputItems?.length === 0,
      },
      {
        label: 'Conditional Variables',
        items: conditionalVariableItems,
        disabled: conditionalVariableItems?.length === 0,
      },
      {
        label: 'Workflow Inputs',
        items: workflowInputsItems,
        disabled: workflowInputsItems?.length === 0,
      },
      {
        label: 'Predefined Values',
        items: predefinedValuesItems,
        disabled: true,
      },
    ],
  };
  return menuData;
};
