import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import AlertCode from '../../constants/alertCodes';
import ColorPicker from '../../components/Branding/ColorPicker';
import EditLogo from '../../components/Branding/EditLogo';
import useShowErrorAlert from '../../utils/customHooks';
import {
  selectAccentColorDisabledButtonKeys,
  selectAccentColorHoverButtonKeys,
  selectAccentColorKeys,
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectPrimaryColorKeys,
  selectPrimaryColorSubsetKeys,
  updateCustomUiConfig,
} from '../../reducers/editBranding';
import { selectSelectedWorkflow } from '../../reducers/workflow';
import { updateReloadSdk } from '../../reducers/dynamicForm';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import useApiHooks from '../../utils/useApiHooks';

function EditSDKBranding() {
  const showErrorAlert = useShowErrorAlert();
  const dispatch = useDispatch();

  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const primaryColorKeys = useSelector(selectPrimaryColorKeys);
  const primaryColorSubsetKeys = useSelector(selectPrimaryColorSubsetKeys);
  const accentColorDisabledButtonKeys = useSelector(selectAccentColorDisabledButtonKeys);
  const accentColorHoverButtonKeys = useSelector(selectAccentColorHoverButtonKeys);
  const accentColorKeys = useSelector(selectAccentColorKeys);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const customUiConfig = useSelector(selectCustomUIConfig);

  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');

  const uiConfig = Object.keys(customUiConfig).length > 0 ? customUiConfig : defaultUiConfig;

  const [primaryColor, setPrimaryColor] = useState((() => {
    if (uiConfig.colors.primaryColor) return uiConfig.colors.primaryColor;
    const existingColorKey = primaryColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors[existingColorKey];
  })());
  const [accentColor, setAccentColor] = useState((() => {
    const existingColorKey = accentColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors.accentColor || uiConfig.colors[existingColorKey];
  })());

  const getUpdatedUiConfig = () => {
    const editedUiConfig = cloneDeep(uiConfig);
    const primaryOpacity = 'cc';
    const disabledButtonOpacity = '80';
    const hoverButtonOpacity = '0d';

    Object.keys(editedUiConfig.colors).forEach((key) => {
      if (primaryColorKeys.includes(key)) editedUiConfig.colors[key] = primaryColor;
      else if (primaryColorSubsetKeys.includes(key)) editedUiConfig.colors[key] = `${primaryColor}${primaryOpacity}`;
      else if (accentColorDisabledButtonKeys.includes(key)) editedUiConfig.colors[key] = `${accentColor}${disabledButtonOpacity}`;
      else if (accentColorHoverButtonKeys.includes(key)) editedUiConfig.colors[key] = `${accentColor}${hoverButtonOpacity}`;
      else if (accentColorKeys.includes(key)) editedUiConfig.colors[key] = accentColor;
    });
    editedUiConfig.colors.primaryColor = primaryColor;
    editedUiConfig.colors.accentColor = accentColor;
    return editedUiConfig;
  };

  const updateUiConfigColors = () => {
    try {
      const editedUiConfig = getUpdatedUiConfig();
      dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
      const editedWorkflowConfig = cloneDeep(selectedWorkflow);
      editedWorkflowConfig.properties.uiConfigSource = 'custom';
      updateWorkflowInState(editedWorkflowConfig);
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.updateUiConfigs });
    }
  };

  const handleColorChange = (colorType, colorValue) => {
    if (colorType === 'primary') setPrimaryColor(colorValue);
    else if (colorType === 'accent') setAccentColor(colorValue);
  };

  const { getLogoUrl, uploadLogo } = useApiHooks();

  // upload logo to s3 and get the url for ui config
  const updateLogo = async (logoBlob) => {
    const uploadResponse = await uploadLogo(workflowId, logoBlob);
    let logoUrl = uploadResponse?.fileUrl;
    if (!logoUrl) {
      logoUrl = await getLogoUrl(workflowId);
    }
    if (!logoUrl) return null;

    const logos = {
      clientLogo: logoUrl,
    };
    const editedUiConfig = {
      ...cloneDeep(uiConfig),
      logos,
    };
    dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    dispatch(updateReloadSdk());
    return logoUrl;
  };

  useEffect(() => {
    updateUiConfigColors();
  }, [primaryColor, accentColor]);

  return (
    <div>
      <ColorPicker
        type="primary"
        name="Text Color"
        color={primaryColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <ColorPicker
        type="accent"
        name="Accent Color"
        color={accentColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <EditLogo updateLogo={updateLogo} />
    </div>
  );
}

export default EditSDKBranding;
