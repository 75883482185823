/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { cloneDeep } from 'lodash';
import { selectModules, selectSelectedWorkflow } from '../reducers/workflow';
import updateGotoTagsInWorkflow from '../utils/updateGotoTags';
import updateModuleNamesInWorkflow from '../utils/updateModuleNamesInWorkflow';
import store from '../store';
import convertToNodesEdges from '../components/utils';
import { endStateKeys as endStates } from '../components/constants';
import { isNextStepPresentInDynamicForm, isNextStepOfNodeNotPointingToItSelf } from '../validations/workflowValidation';
import removeUnvisitedNodesAndConditions from './utils';

const preProcessor = (workflow, preOperations) => preOperations.reduce((accumulator, currFn) => currFn(accumulator), workflow);
const validate = (workflow, validators) => {
  let isValid = true;
  let code = null;
  validators.forEach(({ code: errorCode, fn: validator }) => {
    isValid = isValid && validator(workflow);
    if (!code && !isValid) code = errorCode;
  });
  return { isValid, code };
};

const getStateValue = () => store.getState();

const updateModuleNames = (workflow) => {
  const modules = selectModules(getStateValue());
  return updateModuleNamesInWorkflow(workflow, modules);
};

const isTerminalPresentAsNextStep = (components) => {
  if (components?.length === 0) return false;
  let isPresent = false;
  (components || []).forEach((component) => {
    if (component.onClick?.nextStep) {
      const nextStep = component.onClick?.nextStep;
      if (endStates.includes(nextStep)) isPresent = true;
    } else if (component?.subComponents?.length) {
      const subComponents = component?.subComponents || [];
      if (!isPresent) isPresent = isTerminalPresentAsNextStep(subComponents);
    }
  });
  return isPresent;
};

const terminalNotAllowedInDynamicForm = (workflow) => {
  let isValid = true;
  (workflow?.modules || []).forEach((module) => {
    if (module.type === 'dynamicForm') {
      const { components = [] } = module.properties.sections[0];
      if (isValid) isValid = !isTerminalPresentAsNextStep(components);
    }
  });
  return isValid;
};

const anyEndStateReachable = (workflow) => {
  const { isEndStateReachable } = convertToNodesEdges(workflow);
  return isEndStateReachable;
};

const validateCountryModule = (workflow) => {
  const state = store.getState();
  const { countryIdNameMapping } = state.workflow;
  const isPresent = workflow.modules?.[0].type === 'countries';
  const isNotDuplicate =
    (workflow.modules?.filter((module) => module.type === 'countries') || []).length === 1;
  const { countriesSupported } = workflow.modules?.[0].properties || {};
  const allowedCountries = countriesSupported?.filter((country) => Boolean(countryIdNameMapping[country]));
  const notEmpty = allowedCountries?.length >= 1 || 0;
  const allCountriesSupported = allowedCountries?.length === countriesSupported?.length;
  return isPresent && isNotDuplicate && notEmpty && allCountriesSupported;
};

export const validateWorkflow = (workflow) => {
  const validators = [
    { code: 'noEndStateReachable', fn: anyEndStateReachable },
    { code: 'nextStepNotPresentinDynamaicForm', fn: isNextStepPresentInDynamicForm },
    { code: 'nextStepOfNodePointingToSelf', fn: isNextStepOfNodeNotPointingToItSelf },
    // Commenting the below check because its not consistent as we are allowing terminal nodes
    // as next step from other modules
    // { code: 'noTerminalsInDynamicForm', fn: terminalNotAllowedInDynamicForm },
    { code: 'countryModuleNotCorrect', fn: validateCountryModule },
  ];
  return validate(workflow, validators);
};

export const checkNodesGettingDeleted = (oldWorkflow, updatedWorkflow) => {
  const { conditions: oldConditions } = oldWorkflow;
  const { conditions: newConditions } = updatedWorkflow;
  const newConditionIds = Object.keys(newConditions);
  const newModuleIds = updatedWorkflow.modules.map(({ id }) => id);
  const deletedModules = oldWorkflow.modules.filter(({ id }) => !newModuleIds.includes(id)).map(({ id, name: moduleName }) => ({ id, name: moduleName }));
  const deletedConditions = Object.keys(oldConditions).filter((id) => !newConditionIds.includes(id)).map((id) => ({ id, name: oldConditions[id].name }));
  if (deletedModules.length || deletedConditions.length) {
    const deletedModulesStr = deletedModules.reduce((acc, curr) => `${acc}\nmoduleId: ${curr.id} moduleName: ${curr.name || ''}`, '\n');
    const deletedConditionsStr = deletedConditions.reduce((acc, curr) => `${acc}\nconditionId: ${curr.id} conditionName: ${curr.name || ''}`, '\n');
    const finalMessage = `Your action will remove following ${deletedModulesStr} ${deletedConditionsStr}`;
    return { showWarning: true, message: finalMessage };
  }
  return { showWarning: false, message: '' };
};

const checkNodesGettingDeletedFromDynamicForm = (oldWorkflow, updatedWorkflow, sourceType) => {
  if (sourceType !== 'dynamicForm') return { showWarning: false, message: '' };
  return checkNodesGettingDeleted(oldWorkflow, updatedWorkflow);
};

const getFirstWarning = (oldWorkflow, updatedWorkflow, warnings, sourceType) => {
  if (!oldWorkflow?.modules?.length) return { showWarning: false, message: '' };
  let flagged = false;
  let warningMessage = '';
  warnings.some((warningFn) => {
    const { showWarning, message } = warningFn(oldWorkflow, updatedWorkflow, sourceType);
    if (showWarning) {
      flagged = true;
      warningMessage = message;
      return true;
    }
    return false;
  });
  return { showWarning: flagged, message: warningMessage };
};

export const updateWorkflowInState = (workflow, isEdited = true, action = { }) => {
  const { sourceType = null } = action || {};
  const oldWorkflow = selectSelectedWorkflow(getStateValue());
  const preOperations = [updateModuleNames, updateGotoTagsInWorkflow, removeUnvisitedNodesAndConditions];
  const warnings = [checkNodesGettingDeletedFromDynamicForm];
  const operatingWorkflow = cloneDeep(workflow);
  const processedWorkflow = preProcessor(operatingWorkflow, preOperations);
  const { isValid: isValidOperation, code } = validateWorkflow(processedWorkflow);
  const { showWarning, message } = getFirstWarning(oldWorkflow, processedWorkflow, warnings, sourceType);
  if (isValidOperation) {
    // eslint-disable-next-line no-restricted-globals
    if (!showWarning || confirm(message)) {
      store.dispatch({
        type: 'workflow/updateSelectedWorkflow',
        payload: { workflow: processedWorkflow, isEdited },
      });
      return true;
    }
  } else {
    alert(
      `Operation leading to invalid workflow\n${code || 'Something went wrong'}\nTerminated !!!`,
    );
  }
  return false;
};
