import { nonModuleNodes } from '../components/FormModule/utils';
import compile from '../compilerDecompiler/compiler';
import convertToNodesEdges from '../components/utils';
import { formComponentList } from '../reducers/dynamicForm';

export const findModuleNameFromModuleId = (selectedWorkflow, moduleId) => {
  const selectedModule = selectedWorkflow?.modules.find(
    (module) => module.id === moduleId,
  );
  return selectedModule.name;
};

export const getGroupedListOfModules = (
  selectedWorkflow,
  workflowModules,
  supportedScreens,
) => {
  if (!Object.keys(selectedWorkflow).length || !Object.keys(workflowModules).length) {
    return [];
  }
  const workflow = compile(selectedWorkflow, workflowModules, formComponentList);
  const { nodes = [] } = convertToNodesEdges(workflow);

  const listOfModules = nodes.map((node) => (
    !(nonModuleNodes.includes(node.nodeType))
      ? node : null)).filter((id) => id !== null);

  // Update module names
  listOfModules.forEach((module, index) => {
    let moduleName = module.name;
    if (module.superModuleId) {
      moduleName = findModuleNameFromModuleId(selectedWorkflow, module.superModuleId);
    }
    Object.assign(listOfModules[index], { moduleName });
  });

  // Update moduleType
  listOfModules.forEach((module, index) => {
    const individualModuleConfig = workflowModules[`${module.nodeType}`];
    const moduleType = individualModuleConfig?.type || individualModuleConfig?.configFile?.type;
    Object.assign(listOfModules[index], { moduleType });
    // remove countries module when only one country is selected
    if (module.moduleType === 'countries') {
      const filteredModule = selectedWorkflow.modules.filter((mod) => mod.id === module.id)[0];
      if (filteredModule?.properties.countriesSupported.length === 1) listOfModules[index] = null;
    }
  });

  const filteredListOfModules = listOfModules.filter(
    (module) => module?.moduleType && supportedScreens[module?.moduleType],
  );

  const listOfListOfModules = [];
  const idToArrMap = {};
  filteredListOfModules.forEach((module) => {
    const id = module.superModuleId || module.id;
    if (idToArrMap[id]) idToArrMap[id].push(module);
    else idToArrMap[id] = [module];
  });

  Object.entries(idToArrMap).forEach(([key, value]) => {
    listOfListOfModules.push({
      id: key,
      moduleName: value[0].moduleName,
      modules: value,
    });
  });
  return { listOfListOfModules, workflow };
};
