/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const editBrandingSlice = createSlice({
  name: 'editBranding',
  initialState: {
    supportedFonts: [],
    supportedFontWeights: [
      { value: 'Thin', text: 'Thin' },
      { value: 'ExtraLight', text: 'Extra Light' },
      { value: 'Normal', text: 'Normal' },
      { value: 'Medium', text: 'Medium' },
      { value: 'SemiBold', text: 'Semi Bold' },
      { value: 'Bold', text: 'Bold' },
      { value: 'ExtraBold', text: 'Extra Bold' },
      { value: 'Black', text: 'Black' },
    ],
    fontStyleSheets: {},
    screens: {
      countries: [
        {
          name: 'Picker Screen',
          state: 'picker',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'countries_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Subtitle Text',
              textConfig: 'countries_desc',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Primary Button',
              textConfig: 'countries_button',
              type: 'button',
              colors: [
                // {
                //   key: 'primaryButtonBackgroundColor',
                //   name: 'Background color',
                // },
                {
                  key: 'primaryButtonTextColor',
                  name: 'Text color',
                },
                // {
                //   key: 'primaryButtonBorderColor',
                //   name: 'Border color',
                // }
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
            },
            {
              name: 'Search Country Text',
              textConfig: 'countries_searchText',
              type: 'text',
            },
          ],
        },
      ],
      document: [
        {
          name: 'Instructions Screen',
          state: 'instructions',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'docInstructions_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Subtitle Text',
              textConfig: 'docInstructions_desc',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Primary Button',
              textConfig: 'docInstructions_button',
              type: 'button',
              colors: [
              //   {
              //     key: 'primaryButtonBackgroundColor',
              //     name: 'Background color',
              //   },
                {
                  key: 'primaryButtonTextColor',
                  name: 'Text color',
                },
              //   {
              //     key: 'primaryButtonBorderColor',
              //     name: 'Border color',
                // }
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
            },
            {
              name: 'Upload Button',
              textConfig: 'docInstructions_uploadButton',
              property: 'allowUpload',
              type: 'button',
              colors: [
                {
                  key: 'secondaryButtonTextColor',
                  name: 'Text color',
                },
              ],
              font: 'secondaryButtonTextFont',
              fontWeight: 'secondaryButtonTextWeight',
              borderRadius: 'secondaryButtonRadius',
            },
          ],
        },
        {
          name: 'Document Select Screen',
          state: 'picker',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'docPicker_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Subtitle Text',
              textConfig: 'docPicker_desc',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Documents List',
              textConfig: '',
              type: 'picker',
              colors: [
                {
                  key: 'documentSelectTextColor',
                  name: 'Text Color',
                },
                {
                  key: 'documentSelectHoverTextColor',
                  name: 'Hover Text color',
                },
                {
                  key: 'documentSelectBackgroundColor',
                  name: 'Background color',
                }, {
                  key: 'documentSelectHoverBackgroundColor',
                  name: 'Hover Background color',
                },
              ],
            },
          ],
        },
        {
          name: 'Camera Access Screen',
          state: 'cameraPermissionScreen',
          // Not editable currently
          // brandingKeys: [
          //   {
          //     name: 'Camera Access Title',
          //     textConfig: 'docCapture_cameraAccessTitle',
          //     type: 'text',
          //     colors: ['titleTextColor'],
          //     font: 'titleTextFont',
          //     fontWeight: 'titleTextWeight',
          //   },
          //   {
          //     name: 'Camera Access Description',
          //     textConfig: 'docCapture_cameraAccessDesc',
          //     type: 'text',
          //     colors: ['descriptionTextColor'],
          //     font: 'descriptionTextFont',
          //     fontWeight: 'descriptionTextWeight',
          //   },
          //   {
          //     name: 'Camera Access Button',
          //     textConfig: 'docCapture_cameraAccessButton',
          //     type: 'button',
          //     colors: [
          //       {
          //         key: 'laptopEnabledCaptureButtonTextColor',
          //         name: 'Enabled Text color',
          //       },
          //     ],
          //     font: 'primaryButtonTextFont',
          //     fontWeight: 'primaryButtonTextWeight',
          //   },
          // ],
        },
        {
          name: 'Capture Screen',
          state: 'capture',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'docCapture_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Subtitle Text',
              textConfig: 'docCapture_desc',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Front SubText',
              textConfig: 'docCapture_frontSubText',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Back SubText',
              textConfig: 'docCapture_backSubText',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Capture Button',
              textConfig: 'docCapture_button',
              type: 'button',
              colors: [
              //   {
              //     key: 'laptopEnabledCaptureButtonColor',
              //     name: 'Enabled Button color',
              //   },
                {
                  key: 'laptopEnabledCaptureButtonTextColor',
                  name: 'Enabled Text color',
                },
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
            },
            {
              name: 'Capture Button - Inactive State',
              type: 'button',
              colors: [
              //   {
              //     key: 'laptopDisabledCaptureButtonColor',
              //     name: 'Disabled Button color',
              //   },
                {
                  key: 'laptopDisabledCaptureButtonTextColor',
                  name: 'Disabled Text color',
                },
              ],
            },
          ],
        },
        {
          name: 'Review Screen',
          state: 'review',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'docReview_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Subtitle Text',
              textConfig: 'docReview_desc',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Use Photo Button',
              textConfig: 'docReview_usePhotoButton',
              type: 'button',
              colors: [
              //   {
              //     key: 'primaryButtonBackgroundColor',
              //     name: 'Background color',
              //   },
                {
                  key: 'primaryButtonTextColor',
                  name: 'Text color',
                },
              //   {
              //     key: 'primaryButtonBorderColor',
              //     name: 'Border color',
              //   }
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
              borderRadius: 'primaryButtonRadius',
            },
            {
              name: 'Retake Button',
              type: 'button',
              textConfig: 'docReview_retakeButton',
              colors: [
              //   {
              //     key: 'secondaryButtonBackgroundColor',
              //     name: 'Background color',
              //   },
                {
                  key: 'secondaryButtonTextColor',
                  name: 'Text color',
                },
              //   {
              //     key: 'secondaryButtonBorderColor',
              //     name: 'Border color',
              //   }
              ],
              font: 'secondaryButtonTextFont',
              fontWeight: 'secondaryButtonTextWeight',
              borderRadius: 'secondaryButtonRadius',
            },
          ],
        },
        {
          name: 'Processing Screen',
          state: 'processing',
          brandingKeys: [

          ],
        },
        {
          name: 'Processing Success Screen',
          state: 'success',
          brandingKeys: [

          ],
        },
        {
          name: 'Processing Failure Screen',
          state: 'failure',
          brandingKeys: [
            {
              name: 'Failure Animation ',
              type: 'lottie',
              animation: 'endStateFailure',
            },
          ],
        },
        {
          name: 'Retake Screen',
          state: 'retake',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'docRetake_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Error Message',
              type: 'errorDropdown',
              textConfig: 'E110',
              textConfigList: [
                'E110',
                'E112',
                'E113',
                'E116',
                'E127',
                'E128',
                'E129',
                'E130',
                'E132',
                'E133',
                'E134',
              ],
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Retake Button',
              textConfig: 'docRetake_button',
              type: 'button',
              colors: [
              //   {
              //     key: 'primaryButtonBackgroundColor',
              //     name: 'Background color',
              //   },
                {
                  key: 'primaryButtonTextColor',
                  name: 'Text color',
                },
              //   {
              //     key: 'primaryButtonBorderColor',
              //     name: 'Border color',
              //   }
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
              borderRadius: 'primaryButtonRadius',
            },
          ],
        },
      ],
      face: [
        {
          name: 'Instructions Screen',
          state: 'instructions',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'faceInstructions_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Subtitle Text',
              textConfig: 'faceInstructions_desc',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Primary Button',
              textConfig: 'faceInstructions_button',
              type: 'button',
              colors: [
              //   {
              //     key: 'primaryButtonBackgroundColor',
              //     name: 'Background color',
              //   },
                {
                  key: 'primaryButtonTextColor',
                  name: 'Text color',
                },
              //   {
              //     key: 'primaryButtonBorderColor',
              //     name: 'Border color',
              //   }
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
              borderRadius: 'primaryButtonRadius',
            },
          ],
        },
        {
          name: 'Camera Access Screen',
          state: 'cameraPermissionScreen',
          // Not editable currently
          // brandingKeys: [
          //   {
          //     name: 'Camera Access Title',
          //     textConfig: 'faceCapture_cameraAccessTitle',
          //     type: 'text',
          //     colors: ['titleTextColor'],
          //     font: 'titleTextFont',
          //     fontWeight: 'titleTextWeight',
          //   },
          //   {
          //     name: 'Camera Access Description',
          //     textConfig: 'faceCapture_cameraAccessDesc',
          //     type: 'text',
          //     colors: ['descriptionTextColor'],
          //     font: 'descriptionTextFont',
          //     fontWeight: 'descriptionTextWeight',
          //   },
          //   {
          //     name: 'Camera Access Button',
          //     textConfig: 'faceCapture_cameraAccessButton',
          //     type: 'button',
          //     colors: [
          //       {
          //         key: 'laptopEnabledCaptureButtonTextColor',
          //         name: 'Enabled Text color',
          //       },
          //     ],
          //     font: 'primaryButtonTextFont',
          //     fontWeight: 'primaryButtonTextWeight',
          //   },
          // ],
        },
        {
          name: 'Capture Screen',
          state: 'capture',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'faceCapture_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Face Found Text',
              textConfig: 'faceCapture_faceFound',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Face Not Found Text',
              textConfig: 'faceCapture_faceNotFound',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Multiple Faces Detected',
              textConfig: 'faceCapture_multipleFaces',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Move Closer Text',
              textConfig: 'faceCapture_moveCloser',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Move Away Text',
              textConfig: 'faceCapture_moveAway',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            {
              name: 'Look Straight Text',
              textConfig: 'faceCapture_lookStraight',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
            // Not supported currently
            // {
            //   name: 'Alert Text',
            //   textConfig: 'faceCapture_alertText',
            //   type: 'text',
            //   colors: ['descriptionTextColor'],
            //   font: 'descriptionTextFont',
            //   fontWeight: 'descriptionTextWeight',
            // },
            {
              name: 'Primary Button',
              textConfig: 'faceCapture_button',
              type: 'button',
              colors: [
              //   {
              //     key: 'laptopEnabledCaptureButtonColor',
              //     name: 'Enabled Button color',
              //   },
                {
                  key: 'laptopEnabledCaptureButtonTextColor',
                  name: 'Enabled Text color',
                },
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
              borderRadius: 'primaryButtonRadius',
            },
            {
              name: 'Primary Button - Inactive State',
              type: 'button',
              colors: [
              //   {
              //     key: 'laptopDisabledCaptureButtonColor',
              //     name: 'Disabled Button color',
              //   },
                {
                  key: 'laptopDisabledCaptureButtonTextColor',
                  name: 'Disabled Text color',
                },
              ],
            },
          ],
        },
        {
          name: 'Processing Screen',
          state: 'processing',
          brandingKeys: [
            {
              name: 'Verifying Text',
              textConfig: 'faceLoader_title',
              type: 'text',
              colors: ['descriptionTextColor'],
              font: 'descriptionTextFont',
              fontWeight: 'descriptionTextWeight',
            },
          ],
        },
        {
          name: 'Processing Success Screen',
          state: 'success',
        },
        {
          name: 'Processing Failure Screen',
          state: 'failure',
        },
        {
          name: 'Retake Screen',
          state: 'retake',
          brandingKeys: [
            {
              name: 'Title Text',
              textConfig: 'faceRetake_title',
              type: 'text',
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Error Message',
              type: 'errorDropdown',
              textConfig: 'E119',
              textConfigList: [
                'E119',
                'E120',
                'E121',
                'E123',
                'E124',
                'E125',
                'E126',
                'E131',
              ],
              colors: ['titleTextColor'],
              font: 'titleTextFont',
              fontWeight: 'titleTextWeight',
            },
            {
              name: 'Retake Button',
              textConfig: 'faceRetake_button',
              type: 'button',
              colors: [
              //   {
              //     key: 'primaryButtonBackgroundColor',
              //     name: 'Background color',
              //   },
                {
                  key: 'primaryButtonTextColor',
                  name: 'Text color',
                },
              //   {
              //     key: 'primaryButtonBorderColor',
              //     name: 'Border color',
              //   }
              ],
              font: 'primaryButtonTextFont',
              fontWeight: 'primaryButtonTextWeight',
            },
          ],
        },
      ],
      api: [
        {
          name: 'Processing Screen',
          state: 'processing',
          brandingKeys: [
            {
              name: 'Processing Animation',
              type: 'lottie',
              animation: 'endStateProcessing',
            },
          ],
        },
        {
          name: 'Processing Success Screen',
          state: 'success',
          brandingKeys: [
            {
              name: 'Success Animation ',
              type: 'lottie',
              animation: 'endStateSuccess',
            },
          ],
        },
        {
          name: 'Processing Failure Screen',
          state: 'failure',
          brandingKeys: [
            {
              name: 'Failure Animation ',
              type: 'lottie',
              animation: 'endStateFailure',
            },
          ],
        },
      ],
      webview: [
        {
          name: 'Processing Screen',
          state: 'processing',
          brandingKeys: [
            {
              name: 'Processing Animation',
              type: 'lottie',
              animation: 'endStateProcessing',
            },
          ],
        },
      ],
      dynamicForm: [
        {
          name: 'Form Module',
        },
      ],
      form: [
        {
          name: 'Form Module',
        },
      ],
    },
    brandingElements: [],
    selectedLanguage: 'en',
    selectedScreenState: '',
    selectedModuleState: '',
    selectedModuleSubType: '',
    selectedModuleType: '',
    defaultConfigs: {},
    defaultUiConfig: {
      colors: {
        titleTextColor: '#050552',
        descriptionTextColor: '#050552cc',
        statusTextColor: '#050552cc',
        primaryButtonBackgroundColor: '#554ef1',
        primaryButtonTextColor: '#FFFFFF',
        primaryButtonBorderColor: '#554ef1',
        primaryButtonDisabledBorderColor: '#cfddff',
        primaryButtonDisabledBackgroundColor: '#cfddff',

        secondaryButtonBackgroundColor: '#FFFFFF',
        secondaryButtonBorderColor: '#554ef1',
        secondaryButtonTextColor: '#554ef1',
        captureButtonColor: '#050552',
        laptopEnabledCaptureButtonColor: '#554ef1',
        laptopDisabledCaptureButtonColor: '#cfddff',
        laptopEnabledCaptureButtonTextColor: '#FFFFFF',
        laptopDisabledCaptureButtonTextColor: '#FFFFFF',
        retakeMessageColor: '#050552cc',
        documentSelectTextColor: '#050552cc',
        documentSelectHoverTextColor: '#5542f1',
        documentSelectBackgroundColor: '#FFFFFF',
        documentSelectHoverBackgroundColor: '#554ef10d',
        formPlaceHolderTextColor: '#05055266',
        qrTitleTextColor: '#050552',
        qrDescriptionTextColor: '#050552cc',
        formHintTextBackgroundColor: '#f7f6fe',
        formHintTextColor: '#554ef1',
        modalBackgroundColor: '#FFFFFF',
        primaryColor: '#050552',
        accentColor: '#554ef1',
        dividerBorderColor: '#0505521a',
      },
      font: {
        titleTextFont: 'Inter',
        descriptionTextFont: 'Inter',
        retakeMessageFont: 'Inter',
        primaryButtonTextFont: 'Inter',
        secondaryButtonTextFont: 'Inter',
        qrTitleTextFont: 'Inter',
        qrDescriptionTextFont: 'Inter',
        statusTextFont: 'Inter',
        formHintTextFont: 'Inter',
      },
      fontWeight: {
        titleTextWeight: 'Semibold',
        statusTextWeight: 'Regular',
        descriptionTextWeight: 'Regular',
        retakeMessageTextWeight: 'Regular',
        primaryButtonTextWeight: 'Semibold',
        secondaryButtonTextWeight: 'Semibold',
        qrTitleTextWeight: 'Semibold',
        qrDescriptionTextWeight: 'Regular',
        formHintTextWeight: 'bold',
      },
      borderRadius: {
        primaryButtonRadius: 20,
        secondaryButtonRadius: 20,
      },
      fontSize: {
        titleTextSize: 20,
        statusTextSize: 14,
        descriptionTextSize: 14,
        documentSideHintTextSize: 8,
        retakeMessageTextSize: 14,
        primaryButtonTextSize: 14,
        secondaryButtonTextSize: 14,
        alertTextBoxTextSize: 14,
        formHintTextSize: 10,

      },
      animation: {
        documentInstructionLottie: 'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/images/IDCaptureInstructions.lottie',
        documentProcessingLottie: '',
        documentSuccessLottie: '',
        documentFailureLottie: '',
        faceInstructionLottie: 'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/images/selfieInstructions.lottie',
        faceProcessingLottie: '',
        faceSuccessLottie: '',
        faceFailureLottie: '',
        endStateProcessing: '',
        endStateSuccess: 'https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/images/newApiSuccessLottie.lottie',
        endStateFailure: 'https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/images/newApiFailureLottie.lottie',
      },
    },
    customUiConfig: {},
    customTextConfig: {},
    primaryColorKeys: ['titleTextColor', 'qrTitleTextColor', 'statusTextColor', 'documentSelectHoverTextColor', 'captureButtonColor'],
    primaryColorSubsetKeys: ['descriptionTextColor', 'statusTextColor', 'retakeMessageColor', 'documentSelectTextColor', 'qrDescriptionTextColor', 'formPlaceHolderTextColor'],
    accentColorDisabledButtonKeys: ['laptopDisabledCaptureButtonColor', 'primaryButtonDisabledBackgroundColor', 'primaryButtonDisabledBorderColor'],
    accentColorHoverButtonKeys: ['documentSelectHoverBackgroundColor'],
    accentColorKeys: ['primaryButtonBackgroundColor', 'laptopEnabledCaptureButtonColor', 'primaryButtonBorderColor', 'secondaryButtonBorderColor', 'secondaryButtonTextColor'],
    closeBrandingWindow: false,
    closeSDK: false,
  },
  reducers: {
    updateCloseSDK: (state, action) => {
      const { closeSDK } = action.payload;
      state.closeSDK = closeSDK;
    },
    updateSelectedScreenState: (state, action) => {
      const { screen } = action.payload;
      state.selectedScreenState = screen;
    },
    updateSelectedModuleState: (state, action) => {
      const { module } = action.payload;
      state.selectedModuleState = module.id;
    },
    updateSelectedModuleSubType: (state, action) => {
      const { module } = action.payload;
      state.selectedModuleSubType = module.nodeType;
    },
    updateSelectedModuleType: (state, action) => {
      const { module } = action.payload;
      state.selectedModuleType = module.moduleType;
      state.selectedScreenState = state.screens[module?.moduleType] ? state.screens[module.moduleType][0]?.state : 'na';
    },
    updateCustomUiConfig: (state, action) => {
      const { uiConfig } = action.payload;
      state.customUiConfig = uiConfig;
    },
    updateCustomTextConfig: (state, action) => {
      const { textConfig, language } = action.payload;
      state.customTextConfig[language] = textConfig;
    },
    updateDefaultConfigs: (state, action) => {
      const { configs } = action.payload;
      state.defaultConfigs = configs.defaultTextConfigs;
    },
    updateCloseBrandingWindow: (state, action) => {
      const { closeBrandingWindow } = action.payload;
      state.closeBrandingWindow = closeBrandingWindow;
    },
    updateSupportedFonts: (state, action) => {
      const { fonts } = action.payload;
      state.supportedFonts = fonts;
    },
    updateBrandingElements: (state, action) => {
      const { brandingElements } = action.payload;
      state.brandingElements = brandingElements;
    },
    updateFontStyleSheets: (state, action) => {
      const { fontStyleSheets } = action.payload;
      state.fontStyleSheets = { ...state.fontStyleSheets, ...fontStyleSheets };
    },
    resetBrandingScreens: (state) => {
      state.selectedScreenState = '';
      state.selectedModuleState = '';
      state.selectedModuleSubType = '';
      state.selectedModuleType = '';
    },
    updateSelectedLanguage: (state, action) => {
      const { language } = action.payload;
      state.selectedLanguage = language;
    },
  },
});

export const selectSelectedModuleType = (state) => state.editBranding.selectedModuleType;
export const selectSelectedModuleSubType = (state) => state.editBranding.selectedModuleSubType;
export const selectSelectedModuleState = (state) => state.editBranding.selectedModuleState;
export const selectSelectedScreenState = (state) => state.editBranding.selectedScreenState;
export const selectCloseSDK = (state) => state.editBranding.closeSDK;
export const selectCustomUIConfig = (state) => state.editBranding.customUiConfig;
export const selectPrimaryColorKeys = (state) => state.editBranding.primaryColorKeys;
export const selectPrimaryColorSubsetKeys = (state) => state.editBranding.primaryColorSubsetKeys;
export const selectDefaultUIConfig = (state) => state.editBranding.defaultUiConfig;
export const selectAccentColorDisabledButtonKeys =
  (state) => state.editBranding.accentColorDisabledButtonKeys;
export const selectAccentColorHoverButtonKeys =
  (state) => state.editBranding.accentColorHoverButtonKeys;
export const selectAccentColorKeys = (state) => state.editBranding.accentColorKeys;
export const selectDefaultTextConfig = (state) => state.editBranding.defaultConfigs;
export const selectCustomTextConfig =
  (state) => state.editBranding.customTextConfig[state.editBranding.selectedLanguage];
export const selectAllCustomTextConfigs = (state) => state.editBranding.customTextConfig;
export const selectSupportedFonts = (state) => state.editBranding.supportedFonts;
export const selectSupportedFontWeights = (state) => state.editBranding.supportedFontWeights;
export const selectSelectedLanguage = (state) => state.editBranding.selectedLanguage;
export const selectFontStyleSheets = (state) => Object.values(state.editBranding.fontStyleSheets);

// Action creators are generated for each case reducer function
export const {
  updateSelectedScreenState, updateSelectedModuleState, updateCustomUiConfig, updateDefaultConfigs,
  updateCloseBrandingWindow, updateSelectedModuleSubType,
  updateBrandingElements, updateSelectedModuleType,
  updateCustomTextConfig, updateSupportedFonts, updateFontStyleSheets, resetBrandingScreens,
  updateCloseSDK, updateSelectedLanguage,
} = editBrandingSlice.actions;
export default editBrandingSlice.reducer;
