import { useEffect, useState } from 'react';
import './NewConditionDrawer.scss';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import {
  selectSelectedNode,
  selectSelectedWorkflow, selectOrderOfNodes, selectModules,
} from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { editIfTrueIfFalseReason, swapBranchesInCondition } from '../../../../containers/workflowOperations';
import { logConditionRuleUpdate } from '../../../../logger/logHighLevelWorkflowUpdates';
import ConditionsDrawer from '../../../Conditions/ConditionsDrawer';
import { createNestedMenuData } from '../NestedDropdown/utils';
import { selectFormComponents } from '../../../../reducers/dynamicForm';
import {
  getConditionalVariables, getModuleOutputs, getWorkflowInputVariables, getPredefinedValues,
} from '../InputsToModule/utils';
import TextInput from '../TextInput/TextInput';

function NewConditionDrawer() {
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const selectedNodeId = useSelector(selectSelectedNode).id;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const moduleConfigs = useSelector(selectModules);
  const formComponentsConfig = useSelector(selectFormComponents);

  const [showConditionEditor, setShowConditionEditor] = useState(false);
  const [rule, setRule] = useState(selectedWorkflow?.conditions[selectedNodeId]?.rule);

  const handleAddConditionClick = () => {
    setShowConditionEditor(true);
  };

  const handleClose = () => {
    setShowConditionEditor(false);
  };

  const onSwapBranch = () => {
    const editedWorkflow = swapBranchesInCondition(selectedNodeId, selectedWorkflow);
    updateWorkflowInState(editedWorkflow);
  };

  const onEditReason = (type, value) => {
    const editedWorkflow = editIfTrueIfFalseReason(selectedWorkflow, selectedNodeId, type, value);
    updateWorkflowInState(editedWorkflow);
  };

  useEffect(() => {
    const editedWorkflow = cloneDeep(selectedWorkflow);
    const selectedRule = editedWorkflow.conditions[selectedNodeId];
    const oldRule = selectedRule.rule;
    if (selectedRule) selectedRule.rule = rule;
    logConditionRuleUpdate({
      id: selectedNodeId,
      oldRule,
      newRule: rule,
    });
    updateWorkflowInState(editedWorkflow);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  useEffect(() => {
    const condition = selectedWorkflow.conditions[selectedNodeId];
    setRule(condition.rule);
  }, [selectedNodeId, orderOfNodes, selectedWorkflow.conditions]);

  const moduleOutputs = getModuleOutputs(
    orderOfNodes,
    selectedNodeId,
    selectedWorkflow,
    formComponentsConfig,
    moduleConfigs,
  );

  const conditionalVariables = getConditionalVariables(selectedWorkflow);

  const workflowInputs = getWorkflowInputVariables(selectedWorkflow);

  const preDefinedValues = getPredefinedValues(selectedWorkflow, formComponentsConfig);

  const dropDownOptions = createNestedMenuData(
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    preDefinedValues,
  ).items;

  return (
    <div className="condition-properties-div">
      <div className="condition-properties-div__title">Branches</div>
      <button
        type="button"
        onClick={handleAddConditionClick}
        className="condition-properties-div__condition-button"
      >
        if (Branch 1)
      </button>
      <button
        type="button"
        className="condition-properties-div__condition-button"
        disabled
      >
        else (Branch 2)
      </button>

      <button
        type="button"
        className="condition-properties-div__swap-button"
        onClick={onSwapBranch}
      >
        Swap branches
      </button>
      <TextInput
        label="If true reason"
        setDefaultValue
        placeholder="Type if true reason"
        onSave={(value) => onEditReason('if_true', value)}
        defaultInput={selectedWorkflow.conditions[selectedNodeId].if_true_reason || ''}

      />
      <TextInput
        label="If false reason"
        setDefaultValue
        placeholder="Type if false reason"
        onSave={(value) => onEditReason('if_false', value)}
        defaultInput={selectedWorkflow.conditions[selectedNodeId].if_false_reason || ''}
      />

      {showConditionEditor && (
      <ConditionsDrawer
        onClose={handleClose}
        rule={rule}
        dropDownOptions={dropDownOptions}
        onConditionSave={(value) => {
          setRule(value);
          setShowConditionEditor(false);
        }}
        allowDefaultRules
        preDefinedValues={preDefinedValues}
      />
      )}
    </div>
  );
}

export default NewConditionDrawer;
