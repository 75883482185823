import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, set, unset } from 'lodash';
import { useLocation } from 'react-router-dom';
import {
  selectFormComponents,
  updateReloadSdk,
  updateSelectedComponentPath,
  selectedComponentPath,
} from '../../reducers/dynamicForm';
import {
  canDeleteComponent as isLastButton, getAllNextSteps, getComponentFromPath, getFormComponents,
  getSelectedComponent, performOpOnWorkflow,
  updateOnReloadDependencyForModule,
} from './helper';
import FormModuleEditProperties from '../../components/FormModule/FormModuleEditProperties';
import { copyUiConfigurations, getDefaultComponent, refreshComponentIds } from '../../components/FormModule/utils';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import {
  selectOrderOfNodes, selectSelectedNode, selectSelectedWorkflow, selectModules,
} from '../../reducers/workflow';
import {
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectSelectedModuleState,
  selectSupportedFonts,
  updateCustomUiConfig,
  updateFontStyleSheets,
} from '../../reducers/editBranding';
import ListFormModuleComponents from '../../components/FormModule/ListFormModule';
import './FormModuleDrawer.scss';
import {
  logAddFormComponent, logDeleteFormComponent, logDragFormComponent, logSetFormComponentProperty,
} from '../../logger/logHighLevelWorkflowUpdates';
import {
  getConditionalVariables, getModuleOutputs, getPredefinedValues, getWorkflowInputVariables,
} from '../../components/ViewWorkflow/v2/InputsToModule/utils';
import { eventHandlers } from '../../components/constants';

function FormModuleDrawer({ checkDependencies: checkDeletionDependencies }) {
  const dispatch = useDispatch();
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const selectedModuleId = useSelector(selectSelectedModuleState);
  const formComponentsConfig = useSelector(selectFormComponents);
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const {
    pathArray: selectedComponentPathArray,
    basePath: selectedComponentBasePath,
  } = useSelector(selectedComponentPath);
  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const supportedFonts = useSelector(selectSupportedFonts);
  const selectedNodeId = useSelector(selectSelectedNode)?.id;
  const moduleConfigs = useSelector(selectModules);

  const { search } = useLocation();
  const selectedWorkflowId = new URLSearchParams(search).get('id');

  const [selectedModule, setSelectedModule] = useState(
    getSelectedModule(selectedWorkflow, selectedModuleId),
  );
  const [selectedComponent, setSelectedComponent] = useState(
    getSelectedComponent(selectedModule, selectedComponentPathArray, selectedComponentBasePath),
  );

  const currentUiConfig = customUiConfig && Object.keys(customUiConfig).length > 0
    ? customUiConfig
    : defaultUiConfig;

  const preDefinedValues = getPredefinedValues(selectedWorkflow, formComponentsConfig);

  useEffect(() => {
    const module = getSelectedModule(selectedWorkflow, selectedModuleId);
    setSelectedModule(module);
  }, [JSON.stringify(selectedWorkflow), selectedModuleId]);

  useEffect(() => {
    const newSelectedComponent =
      getSelectedComponent(selectedModule, selectedComponentPathArray, selectedComponentBasePath);
    setSelectedComponent(newSelectedComponent);
  }, [JSON.stringify(selectedComponentPathArray), selectedModule, selectedComponentBasePath]);

  const processWorkflowBeforeSave = (inputWorkflow) => {
    const workflow = cloneDeep(inputWorkflow);
    const updatedModules = workflow.modules.map((module) => {
      if (module.type === 'dynamicForm') {
        const updatedModule = updateOnReloadDependencyForModule(module);
        return updatedModule;
      }
      return module;
    });
    workflow.modules = updatedModules;
    return workflow;
  };

  const updateWorkflow = (workflow) => {
    const processedWorkflow = processWorkflowBeforeSave(workflow);
    updateWorkflowInState(processedWorkflow, true, { sourceType: 'dynamicForm' });
    dispatch(updateReloadSdk());
  };

  const handleOnClick = (pathArray, rootPath) => {
    dispatch(updateSelectedComponentPath({ pathArray, basePath: rootPath }));
  };

  const isComponentDependent = (module, pathArray, rootPath, currentWorkflow) => {
    const components = getFormComponents(module, rootPath);
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    const isDependent = checkDeletionDependencies({
      variableId: componentToBeDeleted.id,
      nodeId: selectedModule.id,
      workflow: currentWorkflow,
    });
    return isDependent;
  };

  const handleOnDelete = (pathArray, rootPath, workflow, moduleId, currCustomUiConfig) => {
    const isDependent = isComponentDependent(selectedModule, pathArray, rootPath, workflow);
    if (isDependent) return null;
    const canDelete = isLastButton(selectedModule, pathArray, rootPath);
    if (!canDelete) {
      alert('Can not delete this button as this is the last one');
      return null;
    }
    dispatch(updateSelectedComponentPath({ basePath: rootPath, pathArray: [0] }));

    const module = workflow.modules.find(
      (workflowModule) => workflowModule.id === moduleId,
    );
    const components = getFormComponents(module, rootPath);
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    const editedUiConfig = cloneDeep(currCustomUiConfig);
    unset(editedUiConfig, `${moduleId}.${componentToBeDeleted.id}`);

    const editedWorkflow = performOpOnWorkflow(
      'delete',
      selectedWorkflow,
      selectedModuleId,
      pathArray,
      rootPath,
    );
    dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    updateWorkflow(editedWorkflow);
    logDeleteFormComponent({
      id: selectedModuleId,
      pathArray,
    });
    return null;
  };

  const handleOnCopy = (pathArray, rootPath, workflow, moduleId, currCustomUiConfig) => {
    const currentModule = workflow.modules.find((module) => module.id === moduleId);
    const formComponents = getFormComponents(selectedModule, rootPath);
    const copiedComponent = getComponentFromPath(formComponents, pathArray);
    const { component: updatedComponent, originalToClonedComponentIdMap } = refreshComponentIds(
      copiedComponent,
      currentModule,
      formComponentsConfig,
    );

    const editedWorkflow = performOpOnWorkflow(
      'insert',
      workflow,
      selectedModuleId,
      pathArray,
      rootPath,
      updatedComponent,
    );
    const updatedUiConfig = copyUiConfigurations(
      currCustomUiConfig,
      moduleId,
      originalToClonedComponentIdMap,
    );
    // TODO: Create a helper function for this
    dispatch(updateCustomUiConfig({ uiConfig: updatedUiConfig }));

    // All the nextsteps coming out from the component should be goto.
    const nextSteps = getAllNextSteps([updatedComponent], eventHandlers, false);
    const moduleRef = editedWorkflow.modules.find((module) => module.id === moduleId);
    const existingNextNodeType = moduleRef.next_node_type || {};
    const newNextNodeTypes = nextSteps
      .map(({ componentId, nextStepEvent }) => `${componentId}.${nextStepEvent}.nextStep`)
      .reduce((acc, curr) => Object.assign(acc, { [curr]: 'goto' }), {});
    moduleRef.next_node_type = {
      ...existingNextNodeType,
      ...newNextNodeTypes,
    };
    updateWorkflow(editedWorkflow);
    return null;
  };

  const handleOnUpdate = (
    newComponent,
    workflow,
    moduleId,
    pathArray,
    rootPath,
    componentUIConfig,
    currUiConfig,
  ) => {
    const editedWorkflow = cloneDeep(workflow);
    const editedUiConfig = cloneDeep(currUiConfig);

    // Remove UI config of old component if present
    const module = workflow.modules.find(
      (workflowModule) => workflowModule.id === moduleId,
    );
    const components = getFormComponents(module, rootPath);
    const componentToBeDeleted = getComponentFromPath(components, pathArray);
    const oldCompPath = `${moduleId}.${componentToBeDeleted.id}`;
    const isUiConfigUpdated = unset(editedUiConfig, oldCompPath);
    const hasComponentUiConfig = Boolean(Object.keys(componentUIConfig || {}).length);

    if (hasComponentUiConfig) {
      // Add UI config of new component
      const newCompPath = `${moduleId}.${newComponent.id}`;
      set(editedUiConfig, newCompPath, componentUIConfig);
    }
    if (isUiConfigUpdated || hasComponentUiConfig) {
      editedWorkflow.properties.uiConfigSource = 'custom';
      dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    }
    const workflowToBeUpdated =
    performOpOnWorkflow('update', editedWorkflow, moduleId, pathArray, rootPath, newComponent);
    logSetFormComponentProperty({
      id: moduleId,
      pathArray,
      newComponent,
    });
    updateWorkflow(workflowToBeUpdated);
  };

  const handleOnAdd = (pathArray, rootPath, moduleId, workflow) => {
    // Currently adding the first component by default on add
    dispatch(updateSelectedComponentPath({ pathArray: [0], basePath: rootPath }));
    const module = workflow.modules.find(
      (workflowModule) => workflowModule.id === moduleId,
    );
    const defaultConfig = formComponentsConfig[0];
    const componentToBeAdded = getDefaultComponent(defaultConfig, module);
    const editedWorkflow = performOpOnWorkflow(
      'add',
      workflow,
      moduleId,
      pathArray,
      rootPath,
      componentToBeAdded,
    );
    logAddFormComponent({
      id: selectedModuleId,
      pathArray,
      componentId: componentToBeAdded?.id,
      componentType: componentToBeAdded?.type,
    });
    updateWorkflow(editedWorkflow);
  };

  const handleOnDrag = (fromPathArray, toPathArray, rootPath) => {
    if (fromPathArray !== toPathArray) {
      const formComponents = getFormComponents(selectedModule, rootPath);
      const draggedComponent = getComponentFromPath(formComponents, fromPathArray);
      let editedWorkflow =
      performOpOnWorkflow('delete', selectedWorkflow, selectedModuleId, fromPathArray, rootPath);
      editedWorkflow = performOpOnWorkflow(
        'insert',
        editedWorkflow,
        selectedModuleId,
        toPathArray,
        rootPath,
        draggedComponent,
      );
      logDragFormComponent({
        id: selectedModuleId,
        fromPathArray,
        toPathArray,
      });
      updateWorkflow(editedWorkflow);
    }
  };

  const addnewFontURL = (fontName) => {
    const url = process.env.REACT_APP_CUSTOM_FONT_URL.replace('<SELECTED_FONT>', fontName);
    dispatch(updateFontStyleSheets({ fontStyleSheets: { [fontName]: url } }));
  };

  return (
    <div className="master">
      <ListFormModuleComponents
        formComponents={getFormComponents(selectedModule, 'components')}
        footerComponents={getFormComponents(selectedModule, 'footer.components')}
        handleOnClick={handleOnClick}
        handleOnDelete={(pathArray, rootPath) => handleOnDelete(
          pathArray,
          rootPath,
          selectedWorkflow,
          selectedModuleId,
          customUiConfig,
        )}
        handleOnCopy={(pathArray, rootPath) => handleOnCopy(
          pathArray,
          rootPath,
          selectedWorkflow,
          selectedModuleId,
          customUiConfig,
        )}
        handleOnAdd={(pathArray, rootPath) => handleOnAdd(
          pathArray,
          rootPath,
          selectedModuleId,
          selectedWorkflow,
        )}
        handleOnDrag={handleOnDrag}
        formComponentsConfig={formComponentsConfig}
        selectedComponentPath={selectedComponentPathArray}
        selectedComponentRoot={selectedComponentBasePath}
        selectedComponentLocation={{
          pathArray: selectedComponentPathArray,
          basePath: selectedComponentBasePath,
        }}
      />
      {
       selectedComponent
        && (
        <FormModuleEditProperties
          selectedModule={selectedModule}
          selectedComponent={selectedComponent}
          formComponentsConfig={formComponentsConfig}
          selectedModuleId={selectedModuleId}
          orderOfNodes={orderOfNodes}
          customUiConfig={customUiConfig}
          handleOnComponentChange={(newComponent, componentUIConfig) => {
            handleOnUpdate(
              newComponent,
              selectedWorkflow,
              selectedModuleId,
              selectedComponentPathArray,
              selectedComponentBasePath,
              componentUIConfig,
              currentUiConfig,
            );
          }}
          supportedFonts={supportedFonts}
          currentUiConfig={currentUiConfig}
          addnewFontURL={addnewFontURL}
          workflowInputs={getWorkflowInputVariables(selectedWorkflow)}
          conditionalVariables={getConditionalVariables(selectedWorkflow)}
          moduleOutputs={getModuleOutputs(
            orderOfNodes,
            selectedNodeId,
            selectedWorkflow,
            formComponentsConfig,
            moduleConfigs,
          )}
          selectedWorkflowId={selectedWorkflowId}
          preDefinedValues={preDefinedValues}
        />
        )
      }
    </div>
  );
}

FormModuleDrawer.propTypes = {
  checkDependencies: PropTypes.func.isRequired,
};

export default withDeletionDependencyCheck(FormModuleDrawer);
