import {
  cloneDeep,
  get,
  set,
  unset,
} from 'lodash';
import { logPropertyUnsetForModule, logPropertyUpdateForModule } from '../../../../../logger/logHighLevelWorkflowUpdates';

const updateNestedValue = (obj, path, value) => set(obj, path, value);
const isReqParameter = (workflowKey) => workflowKey.startsWith('requestParameters.');
const isSuperModuleProp = (workflowKey) => workflowKey.includes('[+]');
const isSuperModuleBuilderProp = (workflowKey) => workflowKey.includes('builderProperties[-]');

const getBuilderPropertyKeyFromWorkflowKey = (workflowKey) => {
  const [, propertyName] = workflowKey.split('builderProperties[-]');
  return propertyName || null;
};

const searchAndUpdateRequestParam = (params, key, value) => {
  const clonnedParams = params ? cloneDeep(params) : [];
  const indexToBeUpdated = clonnedParams.findIndex((param) => param.name === key);
  if (indexToBeUpdated === -1) clonnedParams.push(value);
  else clonnedParams[indexToBeUpdated] = value;
  return clonnedParams;
};

const deleteRequestParam = (params, key) => params.filter((param) => param.name !== key);

const getReqParamKeyFromWorkflowKey = (workflowKey) => {
  const [, ...rest] = workflowKey.split('.');
  return rest.join('.');
};

const getParamDetails = (workflowKey) => {
  if (isReqParameter(workflowKey)) {
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    return { isRequestParam: true, key };
  }
  return { isRequestParameter: false, key: workflowKey };
};

const getValueByPath = (obj, path) => get(obj, path);

const unsetNestedValue = (obj, path) => unset(obj, path);

const getParamFromList =
 (paramName, paramList) => (paramList || []).find((param) => param.name === paramName);

const splitStringOnFirstOccurrence = (inputString, delimiter) => {
  const [firstPart, ...remainingParts] = inputString.split(delimiter);
  return [firstPart, remainingParts.join(delimiter)];
};

export const getSourceAndVariable = (value) => {
  if (typeof value === 'string') {
    const [first, second] = splitStringOnFirstOccurrence(value, '.');
    return {
      selectedSource: first,
      selectedVariable: second,
    };
  }
  return {
    selectedSource: '',
    selectedVariable: '',
  };
};

export const getSelectedValueFromModuleInputs = (
  selectedSource,
  selectedVariable,
  selectedWorkflowModules,
) => {
  let finalValue = null;
  const isModuleVariable =
   selectedWorkflowModules.findIndex((mod) => mod.id === selectedSource) !== -1;
  const isConditionalVariable = selectedSource === 'conditionalVariables';
  const isInput = selectedSource === 'inputs';
  if (isConditionalVariable || isModuleVariable || isInput) {
    // conditional variable or module output
    finalValue = `${selectedSource}.${selectedVariable}`;
  }
  return finalValue;
};

export const prepareInputForWorkflowModificationFromCompiler = (
  inputValue,
  currSuperModulePropertyData,
  workflowKey,
) => {
  const [, actualProperty] = workflowKey.split('[+]');
  const { isRequestParam, key } = getParamDetails(actualProperty);
  if (isRequestParam) {
    const { requestParameterType } = currSuperModulePropertyData;
    return {
      name: key,
      type: requestParameterType,
      value: inputValue,
    };
  }
  return inputValue;
};

export const prepareInputForWorkflowModification = (
  inputValue,
  selectedModuleConfig,
  workflowKey,
) => {
  const { isRequestParam, key } = getParamDetails(workflowKey);
  if (isRequestParam) {
    const defaultRequestParameter = selectedModuleConfig?.properties?.requestParameters?.find(
      (param) => param.name === key,
    ) || {};
    const { type } = defaultRequestParameter;
    return {
      name: key,
      type,
      value: inputValue,
    };
  }
  return inputValue;
};

export const fetchCurrentValueFromWorkflow = (
  selectedModule,
  workflowKey,
) => {
  if (!selectedModule) return null;
  const isSuperModuleProperty = isSuperModuleProp(workflowKey);
  const isRequestParameter = isReqParameter(workflowKey);
  const isBuilderProp = isSuperModuleBuilderProp(workflowKey);

  if (isBuilderProp) {
    const propertyName = getBuilderPropertyKeyFromWorkflowKey(workflowKey);
    return selectedModule.builderProperties[propertyName];
  } if (isSuperModuleProperty) {
    return selectedModule.properties[workflowKey];
  } if (isRequestParameter) {
    // Assumption : requestParameter is of type `requestParameters.<someAttribute>`
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    const paramFromWorkflow = getParamFromList(key, selectedModule.properties.requestParameters);
    return paramFromWorkflow?.value;
  }
  // accessing normal object properties workflowKey: a.b.c
  return getValueByPath(selectedModule.properties, workflowKey);
};

export const getCurrentValueFromWorkflowForModuleInputs = (
  selectedModule,
  workflowKey,
) => {
  const currentValue = fetchCurrentValueFromWorkflow(
    selectedModule,
    workflowKey,
  ) || null;
  return getSourceAndVariable(currentValue);
};

export const getCurrentValueFromWorkflowForSingleSelectDropDown = (
  selectedModule,
  workflowKey,
) => {
  const currentValue = fetchCurrentValueFromWorkflow(
    selectedModule,
    workflowKey,
  ) || null;
  return currentValue;
};

export const getSelectedModule = (workflow, moduleId) => (workflow?.modules || []).find(
  (module) => module.id === moduleId,
);

export const fetchCurrentValueFromWorkflowConfig = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
) => {
  const selectedModule = getSelectedModule(selectedWorkflow, selectedModuleId);
  if (!selectedModule) return null;
  return fetchCurrentValueFromWorkflow(selectedModule, workflowKey);
};

export const setDefaultParams = (
  requestParamCombination,
  selectedModuleConfig,
  selectedWorkflow,
  selectedModuleId,
) => {
  const defaultRequestParams = selectedModuleConfig?.properties?.requestParameters;
  const clonnedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex =
  clonnedWorkflow.modules.findIndex((module) => module.id === selectedModuleId);

  if (selectedModuleIndex === -1) return clonnedWorkflow;

  const existingRequestParams = clonnedWorkflow.modules[selectedModuleIndex]
    ?.properties?.requestParameters;
  const newRequestParams = requestParamCombination.map((paramName) => {
    const existingParamFromWorkflow = getParamFromList(paramName, existingRequestParams);
    if (existingParamFromWorkflow) return existingParamFromWorkflow;
    const defaultParam = getParamFromList(paramName, defaultRequestParams);
    return defaultParam;
  });

  const updatedParams = newRequestParams.filter((param) => param);
  clonnedWorkflow.modules[selectedModuleIndex].properties.requestParameters = updatedParams;

  return clonnedWorkflow;
};

export const setModuleProperty = (
  workflowKey,
  inputValue,
  properties,
) => {
  const value = inputValue;
  let editedProperties = cloneDeep(properties);
  const isSuperModuleProperty = isSuperModuleProp(workflowKey);
  const isRequestParameter = isReqParameter(workflowKey);
  if (isSuperModuleProperty) {
    editedProperties[workflowKey] = value;
  } else if (isRequestParameter) {
    // Assumption : requestParameter is of type `requestParameters.<someAttribute>`
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    if (key) {
      const paramsFromWorkflow = editedProperties.requestParameters;
      const newRequestParams = searchAndUpdateRequestParam(paramsFromWorkflow, key, value);
      editedProperties.requestParameters = newRequestParams;
    }
  } else {
    // generic object case
    editedProperties = updateNestedValue(
      editedProperties,
      workflowKey,
      value,
    );
  }
  return editedProperties;
};

export const setBuilderProperty = (
  workflowKey,
  inputValue,
  builderProperties,
) => {
  const value = inputValue;
  const editedBuilderProperties = cloneDeep(builderProperties);
  const [, propertyName] = workflowKey.split('builderProperties[-]');
  updateNestedValue(editedBuilderProperties, propertyName, value);
  return editedBuilderProperties;
};

const logModuleUpdates = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
  value,
) => {
  try {
    const currentValue = fetchCurrentValueFromWorkflowConfig(
      selectedWorkflow,
      selectedModuleId,
      workflowKey,
    );
    if (value === null) {
      logPropertyUnsetForModule({
        id: selectedModuleId,
        workflowKey,
        oldValue: currentValue,
      });
    } else {
      logPropertyUpdateForModule({
        id: selectedModuleId,
        workflowKey,
        oldValue: currentValue,
        newValue: value,
      });
    }
  } catch (err) {
    // TODO: Handle the error
    // console.log(err);
  }
};

export const setModulePropertyInWorkflow = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
  value,
  selectedModuleConfig,
) => {
  logModuleUpdates(
    selectedWorkflow,
    selectedModuleId,
    workflowKey,
    value,
  );
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex = selectedWorkflow.modules.findIndex(
    (module) => module.id === selectedModuleId,
  );
  if (selectedModuleIndex === -1) return editedWorkflow;
  if (isSuperModuleBuilderProp(workflowKey)) {
    // For primitive module
    const inputValue = prepareInputForWorkflowModification(
      value,
      selectedModuleConfig,
      workflowKey,
    );
    const editedBuilderProperties = setBuilderProperty(
      workflowKey,
      inputValue,
      editedWorkflow.modules[selectedModuleIndex].builderProperties,
    );
    editedWorkflow.modules[selectedModuleIndex].builderProperties = editedBuilderProperties;
  } else {
    const inputValue = prepareInputForWorkflowModification(
      value,
      selectedModuleConfig,
      workflowKey,
    );
    const editedProperties = setModuleProperty(
      workflowKey,
      inputValue,
      editedWorkflow.modules[selectedModuleIndex].properties,
    );
    editedWorkflow.modules[selectedModuleIndex].properties = editedProperties;
  }
  return editedWorkflow;
};

export const setModuleVariablesInWorkflow = (
  selectedWorkflow,
  selectedModuleId,
  value,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex = selectedWorkflow.modules.findIndex(
    (module) => module.id === selectedModuleId,
  );
  if (selectedModuleIndex === -1) return editedWorkflow;
  editedWorkflow.modules[selectedModuleIndex].variables = value;
  return editedWorkflow;
};

export const unsetModulePropertyInWorkflow = (
  selectedWorkflow,
  selectedModuleId,
  workflowKey,
) => {
  logModuleUpdates(
    selectedWorkflow,
    selectedModuleId,
    workflowKey,
    null,
  );
  const editedWorkflow = cloneDeep(selectedWorkflow);
  const selectedModuleIndex = selectedWorkflow.modules.findIndex(
    (module) => module.id === selectedModuleId,
  );
  if (selectedModuleIndex === -1) return editedWorkflow;

  const isSuperModuleBuilderProperty = isSuperModuleBuilderProp(workflowKey);
  const isSuperModuleProperty = isSuperModuleProp(workflowKey);
  const isRequestParameter = isReqParameter(workflowKey);
  if (isSuperModuleBuilderProperty) {
    const property = getBuilderPropertyKeyFromWorkflowKey(workflowKey);
    delete editedWorkflow.modules[selectedModuleIndex].builderProperties[property];
  } else if (isSuperModuleProperty) {
    delete editedWorkflow.modules[selectedModuleIndex].properties[workflowKey];
  } else if (isRequestParameter) {
    // Assumption : requestParameter is of type `requestParameters.<someAttribute>`
    const key = getReqParamKeyFromWorkflowKey(workflowKey);
    if (key) {
      const paramsFromWorkflow =
            editedWorkflow.modules[selectedModuleIndex].properties.requestParameters;
      const newRequestParams = deleteRequestParam(paramsFromWorkflow, key);
      editedWorkflow.modules[selectedModuleIndex].properties.requestParameters = newRequestParams;
    }
  } else {
    // generic object case
    unsetNestedValue(
      editedWorkflow.modules[selectedModuleIndex].properties,
      workflowKey,
    );
  }
  return editedWorkflow;
};

export const convertV1OutputToV2Output = (v1Output = {}) => Object.entries(
  v1Output,
).map(([keyName, { name, description }]) => ({
  type: 'outputItem',
  displayName: name,
  description,
  key: keyName,
}));
