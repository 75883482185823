import getWorkflowById from '../../../actions/getWorkflowById';
import AlertCode from '../../../constants/alertCodes';

const updateWorkflow = async (
  workflowData,
  updateWorkflowRowData,
  handleModal,
  updateWorkflowId,
  currentAppId,
  publishWorkflow,
  handleWorkflowUpdated,
  showErrorAlert,
  setWorkflowData,
) => {
  try {
    const { name, description } = workflowData;
    const updateWorkflowToRenderCopy = { ...updateWorkflowRowData };
    updateWorkflowToRenderCopy.name = name;
    updateWorkflowToRenderCopy.description = description;
    handleModal();
    const updatedWorkflowCopy = await getWorkflowById(updateWorkflowId, currentAppId);
    updatedWorkflowCopy.properties.name = name;
    updatedWorkflowCopy.properties.description = description;
    const status = await publishWorkflow(currentAppId, updateWorkflowId, updatedWorkflowCopy);
    if (status.isSuccessful) {
      handleWorkflowUpdated(updateWorkflowToRenderCopy);
      setWorkflowData({});
    } else {
      showErrorAlert({ error: status.error, message: AlertCode.error.errorUpdatingWorkflow });
    }
  } catch (error) {
    showErrorAlert({ error, message: AlertCode.error.errorUpdatingWorkflow });
  }
};

export default updateWorkflow;
