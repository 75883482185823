import PropTypes from 'prop-types';
import addImg from '../../assests/icons/addIcon.svg';
import { formComponentsBasePath } from '../../reducers/dynamicForm';
import ListFormModuleComponents from './ListFormModuleComponents';

function ListFormModule(props) {
  const {
    formComponents,
    footerComponents,
    handleOnClick,
    handleOnDelete,
    handleOnAdd,
    handleOnCopy,
    handleOnDrag,
    formComponentsConfig,
    selectedComponentLocation,
  } = props;
  const {
    pathArray: selectedComponentPathArray,
    basePath: selectedComponentRootPath,
  } = selectedComponentLocation;
  const { main: mainComponentsBasePath, footer: footerComponentsBasePath } = formComponentsBasePath;

  return (
    <div className="component-list-div">
      <div className="component-heading">
        <div className="component-list-div__heading">
          UI COMPONENTS
        </div>
        <button type="button" className="component-add-btn" onClick={(event) => { event.stopPropagation(); handleOnAdd([], mainComponentsBasePath); }}>
          <img src={addImg} alt="add" />
        </button>
      </div>
      <ListFormModuleComponents
        formComponents={formComponents}
        handleOnClick={(pathArray) => handleOnClick(pathArray, mainComponentsBasePath)}
        handleOnDelete={(pathArray) => handleOnDelete(pathArray, mainComponentsBasePath)}
        handleOnCopy={(pathArray) => handleOnCopy(pathArray, mainComponentsBasePath)}
        handleOnAdd={(pathArray) => handleOnAdd(pathArray, mainComponentsBasePath)}
        handleOnDrag={(fromPathArray, toPathArray) => handleOnDrag(
          fromPathArray,
          toPathArray,
          mainComponentsBasePath,
        )}
        formComponentsConfig={formComponentsConfig}
        selectedComponentPath={
          selectedComponentRootPath === mainComponentsBasePath ?
            selectedComponentPathArray :
            []
        }
      />
      <div className="component-heading">
        <div className="component-list-div__heading">
          FOOTER
        </div>
        <button type="button" className="component-add-btn" onClick={(event) => { event.stopPropagation(); handleOnAdd([], footerComponentsBasePath); }}>
          <img src={addImg} alt="add" />
        </button>
      </div>
      <ListFormModuleComponents
        formComponents={footerComponents}
        handleOnClick={(pathArray) => handleOnClick(pathArray, footerComponentsBasePath)}
        handleOnDelete={(pathArray) => handleOnDelete(pathArray, footerComponentsBasePath)}
        handleOnCopy={(pathArray) => handleOnCopy(pathArray, footerComponentsBasePath)}
        handleOnAdd={(pathArray) => handleOnAdd(pathArray, footerComponentsBasePath)}
        handleOnDrag={(fromPathArray, toPathArray) => handleOnDrag(
          fromPathArray,
          toPathArray,
          footerComponentsBasePath,
        )}
        formComponentsConfig={formComponentsConfig}
        selectedComponentPath={
          selectedComponentRootPath === footerComponentsBasePath ?
            selectedComponentPathArray :
            []
          }
      />
    </div>
  );
}

export default ListFormModule;

ListFormModule.propTypes = {
  formComponents: PropTypes.array.isRequired,
  footerComponents: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  selectedComponentLocation: PropTypes.object.isRequired,
};
